import React from "react";
import {
  SuccessCalendarPaymentDescription,
  SuccessCalendarPaymentIcon,
  SuccessCalendarPaymentStyled,
} from "./successCalendarPayment.styled";

const SuccessCalendarPayment: React.FC = () => {
  return (
    <SuccessCalendarPaymentStyled>
      <SuccessCalendarPaymentIcon />
      <SuccessCalendarPaymentDescription>Покупка прошла успешно!</SuccessCalendarPaymentDescription>
    </SuccessCalendarPaymentStyled>
  );
};

export default SuccessCalendarPayment;
