import styled from "@emotion/styled";
import colors from "../../styles/colors";
import borders from "../../styles/borders";
import indents from "../../styles/indents";

export const InputStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const InputField = styled.input<{ $hasIcon: boolean }>`
  width: calc(100% - ${indents.i32});
  outline: none;
  background: ${colors.white.d2};
  border: none;
  border-radius: ${borders.r2};
  color: ${colors.white.d8};
  padding: ${indents.i12} ${indents.i16};
  font-family: Gilroy, serif;
  font-size: 18px;
  font-weight: 300;

  &::placeholder {
    color: ${colors.white.d7};
  }
`;

export const InputIcon = styled.div<{ $iconColor?: string }>`
  position: absolute;
  width: ${indents.i16};
  height: ${indents.i16};
  right: ${indents.i12};
  top: ${indents.i16};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  svg {
    path {
      fill: ${(props): string => props.$iconColor || colors.purple.d1} !important;
    }
  }
`;
