import styled from "@emotion/styled";
import colors from "../../../../../shared/styles/colors";
import indents from "../../../../../shared/styles/indents";
import Button from "../../../../../shared/components/Button/Button";
import screens from "../../../../../shared/styles/screens";
import Typography from "../../../../../shared/components/Typography/Typography";

export const AboutSlideStyled = styled.div`
  width: calc(100% - (100% - 1150px));
  height: 100%;
  padding: ${indents.i0} calc((100% - 1150px) / 2);
  background: ${colors.white.d1};
  display: flex;
  align-items: flex-start;
  gap: ${indents.i48};

  @media all and (max-width: ${screens.lg}) {
    width: calc(100% - ${indents.i96});
    padding: ${indents.i0} ${indents.i48};
  }

  @media all and (max-width: ${screens.l}) {
    width: calc(100% - ${indents.i64});
    padding: ${indents.i0} ${indents.i32};
    display: grid;
    grid-template-areas:
      "content"
      "image";
    grid-template-rows: auto 1fr;

    .content {
      grid-area: content;
    }

    .image {
      grid-area: image;
    }
  }

  @media all and (max-width: ${screens.md}) {
    width: calc(100% - ${indents.i96});
    padding: ${indents.i0} ${indents.i48};
    display: grid;
    grid-template-areas:
      "image"
      "content";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: ${indents.i0};

    .content {
      margin-top: ${indents.i48};
    }
  }
`;

export const AboutSlideContent = styled.div`
  width: 40%;
  padding-top: ${indents.i48};
  display: flex;
  flex-direction: column;

  @media all and (max-width: ${screens.l}) {
    width: 100%;
  }

  @media all and (max-width: ${screens.m}) {
    padding-top: ${indents.i0};
    margin-top: ${indents.i0};
    align-items: center;
  }
`;

export const AboutSlideText = styled(Typography)`
  margin-bottom: ${indents.i16};
  padding: ${indents.i0};
`;

export const AboutSlidePrice = styled(Typography)`
  font-size: 21px;
  font-weight: 400;
  padding: ${indents.i0} ${indents.i0} ${indents.i24} ${indents.i0};

  span {
    color: ${colors.blue.d2};
  }

  @media all and (max-width: ${screens.l}) {
    text-align: center;
  }
`;

export const AboutSlideAction = styled(Button)`
  width: fit-content;
  padding-left: ${indents.i48};
  padding-right: ${indents.i48};
`;
