import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NotFoundPageBackButton,
  NotFoundPageContent,
  NotFoundPageStyled,
} from "./notFoundPage.styled";
import Typography from "../../../shared/components/Typography/Typography";

const NotFoundPage: React.FC = () => {
  /**
   * HELPERS
   */
  const navigate = useNavigate();

  return (
    <NotFoundPageStyled>
      <NotFoundPageContent>
        <Typography type="h3">Страница не найдена</Typography>
        <NotFoundPageBackButton appearance="primary" onClick={(): void => navigate("/")}>
          На главную
        </NotFoundPageBackButton>
      </NotFoundPageContent>
    </NotFoundPageStyled>
  );
};

export default NotFoundPage;
