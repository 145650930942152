import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetServerDateTime } from "./appInfoThunk";

interface AppInfoState {
  serverDateTime: string | null;
}

const initialState: AppInfoState = {
  serverDateTime: null,
};

const appInfoAdapter = createEntityAdapter<AppInfoState>();

const appInfoSlice = createSlice({
  name: "appInfo",
  initialState: appInfoAdapter.getInitialState(initialState),
  reducers: {
    updateServerDateTime: (state, action: PayloadAction<string>) => {
      state.serverDateTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetServerDateTime.fulfilled, (state, action) => {
      state.serverDateTime = action.payload.now.replace(/-/g, "/");
    });
  },
});

export const { updateServerDateTime } = appInfoSlice.actions;

export default appInfoSlice.reducer;
