const zIndexes = {
  background: -1,
  default: 1,
  topElements: 10,
  popup: 20,
  modal: 30,
  special: 40,
};

export default zIndexes;
