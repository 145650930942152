import React from "react";
import EPaymentFormSteps from "../enums/EPaymentFormSteps";
import SelectWhomForm from "../../ui/components/SelectWhomForm/ui/SelectWhomForm";
import BuyerInfoForm from "../../ui/components/BuyerInfoForm/ui/BuyerInfoForm";
import SuccessCalendarPayment from "../../ui/components/SuccessCalendarPayment/ui/SuccessCalendarPayment";
import FailCalendarPayment from "../../ui/components/FailCalendarPayment/ui/FailCalendarPayment";

const paymentFormSteps: Record<
  EPaymentFormSteps,
  {
    title: string;
    element: (error: string | null) => React.ReactNode;
    actionText: string;
  }
> = {
  [EPaymentFormSteps.whom]: {
    title: "Получатель",
    element: () => <SelectWhomForm />,
    actionText: "Продолжить",
  },
  [EPaymentFormSteps.buyerInfo]: {
    title: "Информация",
    element: () => <BuyerInfoForm />,
    actionText: "Перейти к оплате",
  },
  [EPaymentFormSteps.success]: {
    title: "Успешно",
    element: () => <SuccessCalendarPayment />,
    actionText: "Завершить",
  },
  [EPaymentFormSteps.fail]: {
    title: "Ошибка",
    element: (error: string | null) => <FailCalendarPayment message={error} />,
    actionText: "Повторить попытку",
  },
};

export default paymentFormSteps;
