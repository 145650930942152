import styled from "@emotion/styled";
import indents from "../../../../../../../shared/styles/indents";
import Typography from "../../../../../../../shared/components/Typography/Typography";
import screens from "../../../../../../../shared/styles/screens";
import Input from "../../../../../../../shared/components/Input/Input";

export const BuyerInfoFormStyled = styled.div`
  width: 100%;
`;

export const BuyerInfoFormDescription = styled(Typography)`
  width: 100%;

  @media all and (max-width: ${screens.l}) {
    text-align: center;
  }
`;

export const BuyerInfoFormLineBlock = styled.div<{ $hasBottomMargins?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: ${indents.i8};
  margin-bottom: ${(props): string => (props.$hasBottomMargins ? indents.i8 : indents.i0)};
`;

export const BuyerInfoFormColumnBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BuyerInfoFormInput = styled(Input)`
  width: 100%;
`;
