import React, { useState } from "react";
import {
  AboutSlideAction,
  AboutSlideContent,
  AboutSlidePrice,
  AboutSlideStyled,
  AboutSlideText,
} from "./aboutSlide.styled";
import { SlideImage } from "../../../main/ui/calendarSlides.styled";
import snowBallImg from "../../../../../shared/assets/images/snow-ball.png";
import firstDayToy from "../../../../../shared/assets/images/01-toy.png";
import CalendarPaymentModal from "../../../CalendarPaymentModal/ui/CalendarPaymentModal";
import sendYm from "../../../../../shared/helpers/sendYm";
import indents from "../../../../../shared/styles/indents";
import env from "../../../../../app/env/env";

const AboutSlide: React.FC = () => {
  /**
   * STATE
   */
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  /**
   * HANDLERS
   */
  const setIsOpenModalHandler = (): void => {
    sendYm("advent_button_buy");
    setIsOpenModal(true);
  };

  return (
    <>
      <AboutSlideStyled>
        <AboutSlideContent className="content">
          <AboutSlideText>
            <div style={{ marginBottom: indents.i8 }}>
              Каждый декабрь мы подводим итоги, строим планы, загадываем желания. Но не всегда итоги
              радуют и мечты сбываются.
            </div>
            <div>
              Посмотрите на жизнь как на адвент-календарь, где каждый день открывается новое окошко
              возможностей. Главное — понять, как ими пользоваться.
            </div>
          </AboutSlideText>
          <AboutSlidePrice>
            Стоимость адвент-календаря:
            <br />
            <span>{env.calendarAmount} руб.</span>
          </AboutSlidePrice>
          <AboutSlideAction onClick={setIsOpenModalHandler}>Купить</AboutSlideAction>
        </AboutSlideContent>
        <SlideImage className="image">
          <img src={snowBallImg} alt="SnowBall" />
          <img src={firstDayToy} alt="Toy" />
        </SlideImage>
      </AboutSlideStyled>
      <CalendarPaymentModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
    </>
  );
};

export default AboutSlide;
