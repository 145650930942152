import React from "react";
import { BiCheckCircle, BiError, BiInfoCircle } from "react-icons/bi";
import TAlertType from "../types/TAlertType";
import IAlertStatusInfo from "../interfaces/IAlertStatusInfo";

export const alertStatusesInfo: Record<TAlertType, IAlertStatusInfo> = {
  info: {
    colors: {
      primary: "#5E3FE8",
      secondary: "#cebafc",
    },
    icon: <BiInfoCircle />,
  },
  success: {
    colors: {
      primary: "#5E3FE8",
      secondary: "#cebafc",
    },
    icon: <BiCheckCircle />,
  },
  warning: {
    colors: {
      primary: "#ffb446",
      secondary: "#ffe7ce",
    },
    icon: <BiError />,
  },
  error: {
    colors: {
      primary: "#fa4949",
      secondary: "#ffc8c8",
    },
    icon: <BiError />,
  },
};
