import TAppearance from "../types/TAppearance";
import colors from "../../../styles/colors";

export default {
  appearance: {
    primary: `
      border: 1px solid ${colors.blue.d2};
      background-color: ${colors.blue.d2};
      color: ${colors.white.d1};
      
      svg {
        path {
          fill: ${colors.white.d1};
        }
      }
      
      &:hover {
        background-color: ${colors.blue.d3};
      }
      
      &:disabled {
        background-color: ${colors.blue.d3};
        border: 1px solid ${colors.blue.d3};
        cursor: auto;
      }
    `,
    secondary: `
      border: 1px solid ${colors.white.d2};
      background-color: ${colors.white.d2};
      color: ${colors.blue.d2};
      
      svg {
        path {
          fill: ${colors.white.d1};
        }
      }
      
      &:hover {
        background-color: ${colors.blue.d2};
        color: ${colors.white.d1};
      }
      
      &:disabled {
        background-color: ${colors.purple.d5};
        border: 1px solid ${colors.purple.d5};
        cursor: auto;
      }
    `,
    text: `
      border: 1px solid none;
      background-color: none;
      color: ${colors.black.d9};
      
      svg {
        path {
          fill: ${colors.black.d9};
        }
      }
      
      &:hover {
        color: ${colors.white.d1};
        background-color: ${colors.blue.d2};
      }
      
      &:disabled {
        background-color: ${colors.blue.d3};
        border: 1px solid ${colors.blue.d2};
        cursor: auto;
      }
    `,
    "light-purple": `
      border: 1px solid ${colors.purple.d6};
      background-color: ${colors.purple.d6};
      color: ${colors.black.d9};
      
      svg {
        path {
          fill: ${colors.black.d9};
        }
      }
      
      &:hover {
        background-color: ${colors.white.d2};
        color: ${colors.purple.d5};
      }
      
      &:disabled {
        background-color: ${colors.purple.d5};
        border: 1px solid ${colors.purple.d5};
        cursor: auto;
      }
    `,
    green: `
      border: 1px solid ${colors.green.d1};
      background-color: ${colors.green.d1};
      color: ${colors.white.d1};
      
      svg {
        path {
          fill: ${colors.green.d1};
        }
      }
      
      &:hover {
        background-color: ${colors.green.d2};
      }
    `,
    yellow: `
      border: 1px solid ${colors.yellow.d1};
      background-color: ${colors.yellow.d1};
      color: ${colors.white.d1};
      
      svg {
        path {
          fill: ${colors.yellow.d1};
        }
      }
      
      &:hover {
        background-color: ${colors.yellow.d2};
      }
    `,
    red: `
      border: 1px solid ${colors.red.d1};
      background-color: ${colors.red.d1};
      color: ${colors.white.d1};
      
      svg {
        path {
          fill: ${colors.red.d1};
        }
      }
      
      &:hover {
        background-color: ${colors.red.d2};
      }
    `,
  },
} as {
  appearance: Record<TAppearance, string>;
};
