const borders = {
  r0: "0px",
  r1: "6px",
  r2: "10px",
  r3: "12.5px",
  r4: "25px",
  full: "999px",
};

export default borders;
