import React, { useEffect, useState } from "react";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { Dayjs } from "dayjs";
import {
  OpenAwaitSlideContent,
  OpenAwaitSlideStyled,
  OpenAwaitSlideText,
  OpenAwaitSlideTime,
  OpenAwaitSlideTimeContainer,
  OpenAwaitSlideTimeItem,
} from "./openAwaitSlide.styled";
import { DayNumber, SlideImage } from "../../../main/ui/calendarSlides.styled";
import indents from "../../../../../shared/styles/indents";
import snowBallImg from "../../../../../shared/assets/images/snow-ball.png";
import Typography from "../../../../../shared/components/Typography/Typography";
import declOfNum from "../../../../../shared/helpers/declOfNum";
import ITimeInfo from "../../TimerSlide/model/interfaces/ITimeInfo";
import getCountdownByDate from "../../TimerSlide/model/helpers/getCountdownByDate";

const OpenAwaitSlide: React.FC<{ openCalendarDate: Dayjs }> = ({ openCalendarDate }) => {
  /**
   * STATE
   */
  const [timeInfo, setTimeInfo] = useState<ITimeInfo>({
    hours: null,
    minutes: null,
    seconds: null,
  });

  /**
   * HANDLERS
   */
  const hoursHandlers = (): void => {
    setTimeInfo(
      getCountdownByDate(openCalendarDate) || {
        hours: "",
        minutes: "",
        seconds: "",
      },
    );
  };

  /**
   * EFFECTS
   */
  useEffect(() => {
    hoursHandlers();

    setInterval(() => {
      hoursHandlers();
    }, 1000);
  }, []);

  return (
    <OpenAwaitSlideStyled>
      <DayNumber className="dayNumber" type="h1" style={{ marginTop: indents.i48 }}>
        <IoCalendarNumberOutline />
      </DayNumber>
      <OpenAwaitSlideContent className="content">
        <OpenAwaitSlideText>Открытие календаря - 17 декабря</OpenAwaitSlideText>
      </OpenAwaitSlideContent>
      <SlideImage className="image">
        <img src={snowBallImg} alt="SnowBall" />

        <OpenAwaitSlideTimeContainer>
          <Typography style={{ marginBottom: indents.i8 }}>Откроется через</Typography>
          <OpenAwaitSlideTime>
            <OpenAwaitSlideTimeItem>
              <Typography>{timeInfo.hours}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.hours), ["час", "часа", "часов"])}
              </Typography>
            </OpenAwaitSlideTimeItem>
            <Typography>:</Typography>
            <OpenAwaitSlideTimeItem>
              <Typography>{timeInfo.minutes}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.minutes), ["минута", "минуты", "минут"])}
              </Typography>
            </OpenAwaitSlideTimeItem>
            <Typography>:</Typography>
            <OpenAwaitSlideTimeItem>
              <Typography>{timeInfo.seconds}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.seconds), ["секунда", "секунды", "секунд"])}
              </Typography>
            </OpenAwaitSlideTimeItem>
          </OpenAwaitSlideTime>
        </OpenAwaitSlideTimeContainer>
      </SlideImage>
    </OpenAwaitSlideStyled>
  );
};

export default OpenAwaitSlide;
