import { FaTelegramPlane, FaVk, FaYoutube } from "react-icons/fa";
import React from "react";
import IHeaderSocialItem from "../interfaces/IHeaderSocialItem";
import dzenIcon from "../../../../shared/assets/icons/dzen.svg";

const headerSocialItems: IHeaderSocialItem[] = [
  {
    icon: <FaVk />,
    link: "https://vk.com/dotherapy",
  },
  {
    icon: <FaTelegramPlane />,
    link: "https://t.me/do_therapy",
  },
  {
    icon: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCBykqDFaVt0xkpWsAnRELAw",
  },
  {
    icon: <img src={dzenIcon} alt="dzen" />,
    link: "https://dzen.ru/dotherapy_ru",
  },
];

export default headerSocialItems;
