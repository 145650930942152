import styled from "@emotion/styled";
import { HiOutlineLockClosed } from "react-icons/hi";
import borders from "../../../../../../../shared/styles/borders";
import colors from "../../../../../../../shared/styles/colors";
import indents from "../../../../../../../shared/styles/indents";
import Typography from "../../../../../../../shared/components/Typography/Typography";
import screens from "../../../../../../../shared/styles/screens";
import transitions from "../../../../../../../shared/styles/transitions";
import shadows from "../../../../../../../shared/styles/shadows";

export const CalendarDayCardStyled = styled.div<{ $whiteBackground: boolean }>`
  position: relative;
  width: calc(100% - ${indents.i24});
  min-width: calc(158px - ${indents.i48});
  padding: ${indents.i12};
  background: ${(props): string => (props.$whiteBackground ? colors.white.d1 : "transparent")};
  border-radius: ${borders.r4};
  border: 1px solid ${colors.blue.d2};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ease-in-out ${transitions.t3};
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${shadows.sh2};
  }
`;

export const CalendarDayCardNumber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: ${colors.blue.d2};
  border-radius: 24px ${borders.r0} ${borders.r2} ${borders.r0};
  color: ${colors.white.d1};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  @media all and (max-width: ${screens.md}) {
    font-size: 12px;
  }
`;

export const CalendarDayLockIcon = styled(HiOutlineLockClosed)`
  width: 28px;
  height: 28px;
  color: ${colors.blue.d2};
  margin-bottom: ${indents.i16};

  @media all and (max-width: ${screens.md}) {
    width: 28px;
    height: 28px;
    padding: ${indents.i8};
  }
`;

export const CalendarDayCardImg = styled.img`
  width: 36px;
  height: 36px;
  margin-bottom: ${indents.i8};

  @media all and (max-width: ${screens.md}) {
    width: 44px;
    height: 44px;
  }
`;

export const CalendarDayCardDate = styled(Typography)`
  color: ${colors.blue.d2};
  font-weight: 500;

  @media all and (max-width: ${screens.md}) {
    font-size: 12px;
  }
`;
