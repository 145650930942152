import styled from "@emotion/styled";

export const ErrorPageStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorPageContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 380px;
  }

  h3 {
    text-align: center;
  }
`;
