import styled from "@emotion/styled";

export const CarouselStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CarouselItemsList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
`;

export const CarouselItem = styled.div`
  height: 100%;
  display: flex;
  flex: 0 0 100%;
`;

export const CarouselControls = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
`;

export const CarouselControlsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
`;
