import styled from "@emotion/styled";
import Typography from "../../../../../../shared/components/Typography/Typography";
import indents from "../../../../../../shared/styles/indents";
import colors from "../../../../../../shared/styles/colors";
import transitions from "../../../../../../shared/styles/transitions";

export const MobileSubMenuStyled = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover {
    p {
      color: ${colors.white.d7};
    }

    .mobileSubMenuLinksContainer {
      display: flex;
    }
  }
`;

export const MobileSubMenuLabel = styled(Typography)`
  padding: ${indents.i0};
  color: ${colors.black.d9};
  transition: ease-in-out ${transitions.t3};
  font-family: "CoFo", serif;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: ${indents.i4};

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const MobileSubMenuLinksContainer = styled.div`
  display: none;
  flex-direction: column;
  border-bottom: 1px solid ${colors.white.d4};
`;

export const MobileSubmenuLink = styled.a`
  width: 100%;
  padding: ${indents.i8} ${indents.i0};
  text-decoration: none;
  color: ${colors.black.d9};
  font-family: "CoFo", serif;
  font-size: 14px;
  transition: ease-in-out ${transitions.t3};

  &:hover {
    color: ${colors.white.d7};
  }
`;
