const indents = {
  i0: "0px",
  i4: "4px",
  i8: "8px",
  i12: "12px",
  i16: "16px",
  i24: "24px",
  i32: "32px",
  i48: "48px",
  i64: "64px",
  i96: "96px",
};

export default indents;
