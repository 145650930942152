import React from "react";
import {
  MobileSubMenuLabel,
  MobileSubmenuLink,
  MobileSubMenuLinksContainer,
  MobileSubMenuStyled,
} from "./mobileSubMenu.styled";
import IHeaderLink from "../../../../model/interfaces/IHeaderLink";

const MobileSubMenu: React.FC<{ headerLink: IHeaderLink }> = ({ headerLink }) => {
  return (
    <MobileSubMenuStyled>
      <MobileSubMenuLabel>{headerLink.label}</MobileSubMenuLabel>
      <MobileSubMenuLinksContainer className="mobileSubMenuLinksContainer">
        {headerLink.sublinks?.map((sublink) => (
          <MobileSubmenuLink key={sublink.label} href={sublink.link}>
            {sublink.label}
          </MobileSubmenuLink>
        ))}
      </MobileSubMenuLinksContainer>
    </MobileSubMenuStyled>
  );
};

export default MobileSubMenu;
