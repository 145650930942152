import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { HiOutlineLockClosed } from "react-icons/hi";
import {
  TimerSlideAction,
  TimerSlideContent,
  TimerSlideStyled,
  TimerSlideText,
  TimerSlideTime,
  TimerSlideTimeContainer,
  TimerSlideTimeItem,
} from "./timerSlide.styled";
import { DayNumber, SlideImage } from "../../../main/ui/calendarSlides.styled";
import Typography from "../../../../../shared/components/Typography/Typography";
import ITimeInfo from "../model/interfaces/ITimeInfo";
import getCountdownByDate from "../model/helpers/getCountdownByDate";
import indents from "../../../../../shared/styles/indents";
import snowBallImg from "../../../../../shared/assets/images/snow-ball.png";
import ITimerSlideProps from "../model/interfaces/ITimerSlideProps";
import addPreZeroesForNumber from "../../../../../shared/helpers/addPreZeroesForNumber";
import CalendarDayModal from "../../../CalendarDayModal/ui/CalendarDayModal";
import declOfNum from "../../../../../shared/helpers/declOfNum";

const TimerSlide: React.FC<ITimerSlideProps> = ({ number, text, targetDate, image, openedDay }) => {
  /**
   * STATE
   */
  const [timeInfo, setTimeInfo] = useState<ITimeInfo>({
    hours: null,
    minutes: null,
    seconds: null,
  });
  const [canOpen, setCanOpen] = useState<boolean>(false);
  const [showDayModal, setShowDayModal] = useState<boolean>(false);

  /**
   * HANDLERS
   */
  const openDayHandler = (): void => {
    if (!canOpen) {
      return;
    }

    setShowDayModal(true);
  };

  const hoursHandlers = (): void => {
    setCanOpen(dayjs().tz("Europe/Moscow").isSameOrAfter(targetDate));
    setTimeInfo(getCountdownByDate(targetDate) || { hours: "", minutes: "", seconds: "" });
  };

  const getSnowBallContent = (): React.ReactNode => {
    if (!openedDay) {
      return (
        <TimerSlideTimeContainer>
          <Typography style={{ marginBottom: indents.i8 }}>Откроется через</Typography>
          <TimerSlideTime>
            <TimerSlideTimeItem>
              <Typography>{timeInfo.hours || "00"}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.hours), ["час", "часа", "часов"])}
              </Typography>
            </TimerSlideTimeItem>
            <Typography>:</Typography>
            <TimerSlideTimeItem>
              <Typography>{timeInfo.minutes || "00"}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.minutes), ["минута", "минуты", "минут"])}
              </Typography>
            </TimerSlideTimeItem>
            <Typography>:</Typography>
            <TimerSlideTimeItem>
              <Typography>{timeInfo.seconds || "00"}</Typography>
              <Typography type="span">
                {declOfNum(Number(timeInfo.seconds), ["секунда", "секунды", "секунд"])}
              </Typography>
            </TimerSlideTimeItem>
          </TimerSlideTime>
        </TimerSlideTimeContainer>
      );
    }

    return <img src={`/calendarDays/${image}.png`} alt="decoration" />;
  };

  /**
   * EFFECTS
   */
  useEffect(() => {
    hoursHandlers();

    setInterval(() => {
      hoursHandlers();
    }, 1000);
  }, []);

  return (
    <>
      <TimerSlideStyled>
        <DayNumber className="dayNumber" type="h1">
          {addPreZeroesForNumber(number)}
        </DayNumber>
        <TimerSlideContent className="content">
          <TimerSlideText>{text}</TimerSlideText>

          <TimerSlideAction onClick={openDayHandler}>
            {canOpen ? (
              <>{openedDay ? "Просмотр" : "Открыть «день»"}</>
            ) : (
              <>
                <HiOutlineLockClosed />
                {targetDate.format("DD MMMM YYYY")}
              </>
            )}
          </TimerSlideAction>
        </TimerSlideContent>
        <SlideImage className="image">
          <img src={snowBallImg} alt="SnowBall" />

          {getSnowBallContent()}
        </SlideImage>
      </TimerSlideStyled>
      <CalendarDayModal isOpen={showDayModal} setIsOpen={setShowDayModal} dayNumber={number} />
    </>
  );
};

export default TimerSlide;
