import { createContext } from "react";
import TAlertData from "../types/TAlertData";

const AlertContext = createContext<{
  addAlert: (alertInfo: TAlertData) => void;
  dismissAlert: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ addAlert: () => {}, dismissAlert: () => {} });

export default AlertContext;
