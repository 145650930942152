import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../../entities/user/store/userSlice";
import calendarReducer from "../../entities/calendar/store/calendarSlice";
import appInfoReducer from "../../entities/appInfo/store/appInfoSlice";

const rootReducer = combineReducers({
  appInfo: appInfoReducer,
  userState: userReducer,
  calendarState: calendarReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = (typeof store)["dispatch"];
