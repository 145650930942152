import { ResourceLanguage } from "i18next";

export const ruLang: ResourceLanguage = {
  translation: {
    loggedMain: {
      navbar: {
        courseConstructor: "Конструктор курсов",
      },
    },
  },
};
