import { useContext, useEffect } from "react";
import Cookies, { CookieAttributes } from "js-cookie";
import dayjs from "dayjs";
import { useAppDispatch } from "../../shared/hooks/redux";
import { updateUserData } from "../../entities/user/store/userSlice";
import {
  fetchGetOpenedCalendarDaysRequest,
  fetchGetReceivedPresents,
} from "../../entities/calendar/store/calendarThunk";
import { fetchGetServerDateTime } from "../../entities/appInfo/store/appInfoThunk";
import { updateServerDateTime } from "../../entities/appInfo/store/appInfoSlice";
import store from "../store";
import AlertContext from "../../shared/components/Alert/context/AlertContext";
import setActiveDayByTime from "../../widgets/HomePage/HomePageCarousel/model/helpers/setActiveDayByTime";
import { setActiveTimeSlide } from "../../entities/calendar/store/calendarSlice";

const useInitApp = (): void => {
  /**
   * CONSTANTS
   */
  const cookieAttributes: CookieAttributes = {
    sameSite: "None",
    secure: true,
    domain: process.env.NODE_ENV !== "production" ? "advent.dot.dotherapy.ru" : undefined,
  };

  /**
   * HELPERS
   */
  const dispatch = useAppDispatch();
  const { addAlert } = useContext(AlertContext);

  /**
   * EFFECTS
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      return;
    }

    Cookies.remove("access_token", cookieAttributes);
    Cookies.set("access_token", token, cookieAttributes);
    dispatch(fetchGetServerDateTime())
      .then((res) => {
        if (res.type === "appInfo/getServerDateTime/rejected") {
          addAlert({
            text: "Ошибка авторизации",
            timeout: 3000,
            status: "error",
          });
          return;
        }

        // UPDATE DATE EVERY SECOND
        setInterval((): void => {
          const { serverDateTime } = store.getState().appInfo;

          dispatch(
            updateServerDateTime(
              serverDateTime
                ? dayjs(serverDateTime).add(1, "s").toISOString()
                : dayjs().tz("Europe/Moscow").toISOString(),
            ),
          );
        }, 2000);

        // SET DEFAULT TIME SLIDE
        const {
          appInfo: { serverDateTime },
          calendarState: { daysInfo },
        } = store.getState();
        dispatch(setActiveTimeSlide(setActiveDayByTime(daysInfo, serverDateTime)));

        // SET AUTHORIZED
        dispatch(updateUserData({ authorized: true }));

        // GET OPENED DAYS
        dispatch(fetchGetOpenedCalendarDaysRequest());

        // GET RECEIVED PRESENTS
        dispatch(fetchGetReceivedPresents());
      })
      .catch(() => {
        addAlert({
          text: "Ошибка авторизации",
          timeout: 3000,
          status: "error",
        });
      });
  }, []);
};

export default useInitApp;
