import React from "react";
import {
  FailCalendarPaymentDescription,
  FailCalendarPaymentIcon,
  FailCalendarPaymentStyled,
} from "./failCalendarPayment.styled";

const FailCalendarPayment: React.FC<{ message: string | null }> = ({ message }) => {
  return (
    <FailCalendarPaymentStyled>
      <FailCalendarPaymentIcon />
      <FailCalendarPaymentDescription>
        {message || "Возникла непредвиденная ошибка"}
      </FailCalendarPaymentDescription>
    </FailCalendarPaymentStyled>
  );
};

export default FailCalendarPayment;
