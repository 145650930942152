import styled from "@emotion/styled";
import Button from "../../../../../../../shared/components/Button/Button";
import indents from "../../../../../../../shared/styles/indents";
import Typography from "../../../../../../../shared/components/Typography/Typography";
import screens from "../../../../../../../shared/styles/screens";

export const SelectWhomFormStyled = styled.div`
  width: 100%;
`;

export const SelectWhomFormDescription = styled(Typography)`
  width: 100%;

  @media all and (max-width: ${screens.l}) {
    text-align: center;
  }
`;

export const SelectWhomFormLineBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${indents.i32};
`;

export const SelectWhomFormWhomVariant = styled(Button)`
  width: 100%;
`;
