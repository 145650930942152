import styled from "@emotion/styled";
import indents from "../../../styles/indents";

export const AlertProviderStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const AlertContainer = styled.div`
  position: fixed;
  right: ${indents.i8};
  bottom: ${indents.i8};
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: ${indents.i8};
`;
