import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ICalendarDay from "../interfaces/ICalendarDay";
import daysInfo from "../mock/daysInfo";
import {
  fetchAddPresent,
  fetchGetOpenedCalendarDaysRequest,
  fetchGetReceivedPresents,
} from "./calendarThunk";
import IPresent from "../interfaces/IPresent";

interface CalendarState {
  daysInfo: ICalendarDay[];
  activeTimeSlide: number;
  receivedPresents: IPresent[];
}

const initialState: CalendarState = {
  daysInfo,
  activeTimeSlide: 0,
  receivedPresents: [],
};

const calendarAdapter = createEntityAdapter<CalendarState>();

const calendarSlice = createSlice({
  name: "calendar",
  initialState: calendarAdapter.getInitialState(initialState),
  reducers: {
    updateDayInfo: (state, action: PayloadAction<number>) => {
      state.daysInfo = state.daysInfo.map((day, index) => {
        if (index !== action.payload - 1) {
          return day;
        }

        return {
          ...day,
          opened: true,
        };
      });
    },
    setActiveTimeSlide: (state, action: PayloadAction<number>) => {
      state.activeTimeSlide = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetOpenedCalendarDaysRequest.fulfilled, (state, action) => {
      state.daysInfo = state.daysInfo.map((day) => {
        const dayInfo = action.payload.tasks.find((task) => task.taskId === day.dayNumber);

        if (!dayInfo) {
          return day;
        }

        return {
          ...day,
          opened: dayInfo.status === "VIEWED",
        };
      });
    });
    builder.addCase(fetchGetReceivedPresents.fulfilled, (state, action) => {
      state.receivedPresents = action.payload;
    });
    builder.addCase(fetchAddPresent.fulfilled, (state, action) => {
      state.receivedPresents = [...state.receivedPresents, action.payload];
    });
  },
});

export const { updateDayInfo, setActiveTimeSlide } = calendarSlice.actions;

export default calendarSlice.reducer;
