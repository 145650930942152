import EWhomVariants from "../enums/EWhomVariants";

const whomVariants: { key: EWhomVariants; label: string }[] = [
  {
    key: EWhomVariants.myself,
    label: "Себе",
  },
  {
    key: EWhomVariants.someone,
    label: "В подарок",
  },
];

export default whomVariants;
