import ICarouselSlide from "../../../../shared/components/Carousel/model/interfaces/ICarouselSlide";
import AboutSlide from "../../../../widgets/Calendar/CalendarSlides/AboutSlide/ui/AboutSlide";

const mainPageCarouselSlides: ICarouselSlide[] = [
  {
    key: "about",
    element: <AboutSlide />,
  },
];

export default mainPageCarouselSlides;
