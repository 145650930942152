import IPaymentForm from "../interfaces/IPaymentForm";
import EWhomVariants from "../enums/EWhomVariants";
import EPurchaseStatuses from "../../../../../entities/user/enums/EPurchaseStatuses";

const getInitPaymentFormState = (purchasedCalendar: EPurchaseStatuses | null): IPaymentForm => {
  const form: IPaymentForm = {
    whom: EWhomVariants.myself,
    name: "",
    surname: "",
    buyerEmail: "",
    email: "",
    fromWhom: "",
    message: "",
  };

  if (purchasedCalendar === EPurchaseStatuses.subscribed) {
    form.whom = EWhomVariants.someone;
  }

  return form;
};

export default getInitPaymentFormState;
