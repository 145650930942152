import React from "react";
import { Dayjs } from "dayjs";
import {
  CalendarDaysCardsList,
  CalendarInfoStyled,
  CalendarInfoText,
  CalendarInfoTitle,
} from "./calendarInfo.styled";
import Typography from "../../../../shared/components/Typography/Typography";
import indents from "../../../../shared/styles/indents";
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks/redux";
import CalendarDayCard from "./components/CalendarDayCard/ui/CalendarDayCard";
import { setActiveTimeSlide } from "../../../../entities/calendar/store/calendarSlice";

const CalendarInfo: React.FC<{ openCalendarDate?: Dayjs }> = ({ openCalendarDate }) => {
  /**
   * HELPERS
   */
  const dispatch = useAppDispatch();

  /**
   * STATE
   */
  const {
    appInfo: { serverDateTime },
    calendarState: { daysInfo },
    userState: { authorized },
  } = useAppSelector((state) => state);

  /**
   * HANDLERS
   */
  const setActiveTimeSlideHandler = (cardNumber: number): void => {
    dispatch(setActiveTimeSlide(cardNumber));
  };

  return (
    <>
      <CalendarInfoStyled>
        <CalendarInfoTitle type="h2">Адвент-календарь 2024</CalendarInfoTitle>
        {!authorized && (
          <Typography type="span" style={{ paddingBottom: indents.i0 }}>
            Старт 18 декабря
          </Typography>
        )}
        <CalendarInfoText type="p">
          {authorized ? (
            "Новый год – всегда новый рубеж, преодолевая который мы встречаем «нового себя» и стремимся сделать жизнь лучше. Войди в Новый год вместе с DoTherapy, а наш адвент-календарь поможет в этом."
          ) : (
            <>
              Жизнь как коробка шоколадных конфет: никогда не знаешь, какая начинка тебе попадется
              <br />
              <span>к/ф «Форрест Гамп»</span>
            </>
          )}
        </CalendarInfoText>
        {authorized && openCalendarDate?.isBefore(serverDateTime) && (
          <CalendarDaysCardsList>
            {daysInfo.map((day, index) => (
              <CalendarDayCard
                key={day.dayNumber}
                dayInfo={day}
                onClick={(): void => setActiveTimeSlideHandler(index)}
              />
            ))}
          </CalendarDaysCardsList>
        )}
      </CalendarInfoStyled>
    </>
  );
};

export default CalendarInfo;
