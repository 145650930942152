import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOpenedCalendarDaysRequest } from "../api/getOpenedCalendarDaysRequest";
import { getReceivedPresentsRequest } from "../api/getReceivedPresentsRequest";
import { addPresentRequest } from "../api/addPresentRequest";
import EPresentType from "../enums/EPresentType";

export const fetchGetOpenedCalendarDaysRequest = createAsyncThunk(
  "calendar/getOpenedCalendarDays",
  async () => {
    const response = await getOpenedCalendarDaysRequest();
    return response.data;
  },
);

export const fetchGetReceivedPresents = createAsyncThunk(
  "calendar/getReceivedPresents",
  async () => {
    const response = await getReceivedPresentsRequest();
    return response.data;
  },
);

export const fetchAddPresent = createAsyncThunk(
  "calendar/addPresent",
  async (presentType: EPresentType) => {
    const response = await addPresentRequest(presentType);
    return response.data;
  },
);
