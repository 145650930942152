import React, { useEffect } from "react";
import { AlertBody, AlertHeader, AlertStyled, AlertTitle } from "./styles/alert.styled";
import IAlertProps from "./interfaces/IAlertProps";
import { alertStatusesInfo } from "./constants/alertStatusesInfo";

const Alert: React.FC<IAlertProps> = ({
  status = "info",
  title,
  text,
  timeout = 0,
  handleDismiss,
}) => {
  /**
   * EFFECTS
   */
  useEffect(() => {
    if (timeout > 0 && handleDismiss) {
      const timer = setTimeout(() => {
        handleDismiss();
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <AlertStyled $primary={alertStatusesInfo[status].colors.primary} $timeout={timeout}>
      <AlertHeader $secondary={alertStatusesInfo[status].colors.secondary}>
        {alertStatusesInfo[status].icon}
        {title && <AlertTitle type="b">{title}</AlertTitle>}
      </AlertHeader>
      <AlertBody type="p">{text}</AlertBody>
    </AlertStyled>
  );
};

export default Alert;
