const sendYm = (event: string): void => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ym(88233848, "reachGoal", event);
};

export default sendYm;
