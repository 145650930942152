import styled from "@emotion/styled";
import { IoCloseOutline } from "react-icons/io5";
import ReactPlayer from "react-player";
import colors from "../../../../shared/styles/colors";
import borders from "../../../../shared/styles/borders";
import indents from "../../../../shared/styles/indents";
import screens from "../../../../shared/styles/screens";
import Typography from "../../../../shared/components/Typography/Typography";
import Button from "../../../../shared/components/Button/Button";

export const CalendarDayModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: ${screens.md}) {
    align-items: flex-start;
    margin-top: ${indents.i64};
  }
`;

export const CalendarDayModalContainer = styled.div`
  position: relative;
  width: calc(45% - ${indents.i64});
  padding: ${indents.i32};
  background: ${colors.white.d1};
  border-radius: ${borders.r4};

  @media all and (max-width: ${screens.lg}) {
    width: calc(65% - ${indents.i64});
  }

  @media all and (max-width: ${screens.l}) {
    width: calc(75% - ${indents.i64});
  }

  @media all and (max-width: ${screens.md}) {
    width: calc(85% - ${indents.i48});
    padding: ${indents.i32} ${indents.i24};
  }

  @media all and (max-width: ${screens.m}) {
    width: calc(90% - ${indents.i48});
  }
`;

export const CalendarDayModalNumber = styled(Typography)`
  width: 58px;
  border-bottom: 4px solid ${colors.blue.d2};
  padding: ${indents.i0};
  font-weight: 400;
  text-align: center;

  @media all and (max-width: ${screens.md}) {
    width: 40px;
    border-bottom: 2px solid ${colors.blue.d2};
    font-size: 32px;
  }
`;

export const CalendarDayModalText = styled(Typography)`
  max-height: 50vh;
  overflow-y: auto;
  margin: ${indents.i12} ${indents.i0};
  white-space: pre-line;

  @media all and (max-width: ${screens.md}) {
    font-size: 16px;
    margin: ${indents.i4} ${indents.i0};
  }

  @media all and (max-width: ${screens.md}) {
    font-size: 14px;
  }
`;

export const CalendarDayModalAction = styled.div`
  width: fit-content;
  padding: ${indents.i12} ${indents.i32};
  background: ${colors.blue.d2};
  border-radius: ${borders.r4};
  color: ${colors.white.d1};
  display: flex;
  align-items: center;
  gap: ${indents.i8};

  svg {
    min-width: 20px;
    min-height: 20px;
  }

  @media all and (max-width: ${screens.md}) {
    width: calc(100% - ${indents.i32});
    padding: ${indents.i8} ${indents.i16};
    font-size: 16px;
  }

  @media all and (max-width: ${screens.m}) {
    width: calc(100% - ${indents.i24});
    padding: ${indents.i8} ${indents.i12};
    font-size: 12px;
  }
`;

export const CalendarDayModalPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: fit-content !important;
  margin-top: ${indents.i24};

  video {
    width: 100%;
  }
`;

export const CalendarDayModalDownloadFile = styled(Button)`
  width: fit-content;
  padding-left: ${indents.i32};
  padding-right: ${indents.i32};
  margin-top: ${indents.i24};
  display: flex;
  gap: ${indents.i8};

  svg {
    padding: 0;
    margin: 0;
    font-size: 21px;
  }

  @media all and (max-width: ${screens.md}) {
    width: calc(100% - ${indents.i64});
  }
`;

export const CalendarDayModalClose = styled(IoCloseOutline)`
  position: absolute;
  width: 32px;
  height: 32px;
  top: ${indents.i16};
  right: ${indents.i16};
  cursor: pointer;
`;

export const CalendarDayModalPresents = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${indents.i32};
`;

export const CalendarDayModalPresent = styled.div`
  width: calc(100% - 3px - ${indents.i24});
  padding: 0 ${indents.i12};
  border-left: 3px solid ${colors.blue.d2};

  p {
    padding-top: ${indents.i8};
  }

  @media all and (max-width: ${screens.md}) {
    p {
      font-size: 16px;
    }
  }

  @media all and (max-width: ${screens.m}) {
    p {
      font-size: 12px;
    }
  }
`;

export const CalendarDayModalGetPresentButton = styled(Button)`
  padding-left: ${indents.i32};
  padding-right: ${indents.i32};

  svg {
    font-size: 18px;
    padding: ${indents.i0};
    margin-right: ${indents.i12};
  }

  @media all and (max-width: ${screens.m}) {
    font-size: 12px;

    svg {
      font-size: 16px;
    }
  }
`;

export const CalendarDayModalPresentDescription = styled(Typography)`
  font-size: 14px;
  animation: ease-in-out fadeIn 0.3s;

  a {
    text-decoration: none;
    color: ${colors.blue.d2};
    transition: ease-in-out 0.3s;

    &:hover {
      color: ${colors.blue.d1};
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
