import styled from "@emotion/styled";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import colors from "../../../../../../../shared/styles/colors";
import Typography from "../../../../../../../shared/components/Typography/Typography";

export const SuccessCalendarPaymentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessCalendarPaymentIcon = styled(HiOutlineEmojiHappy)`
  font-size: 48px;
  color: ${colors.blue.d2};
`;

export const SuccessCalendarPaymentDescription = styled(Typography)`
  width: 100%;
  text-align: center;
`;
