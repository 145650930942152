import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  BuyerInfoFormColumnBlock,
  BuyerInfoFormDescription,
  BuyerInfoFormInput,
  BuyerInfoFormLineBlock,
  BuyerInfoFormStyled,
} from "./buyerInfoForm.styled";
import IPaymentForm from "../../../../model/interfaces/IPaymentForm";
import EWhomVariants from "../../../../model/enums/EWhomVariants";
import emailRegexp from "../../../../../../../shared/regexp/emailRegexp";
import { PaymentFormFieldError } from "../../shared/ui/paymentForm.styled";
import indents from "../../../../../../../shared/styles/indents";

const BuyerInfoForm: React.FC = () => {
  /**
   * HELPERS
   */
  const { control, watch } = useFormContext<IPaymentForm>();

  return (
    <BuyerInfoFormStyled>
      <BuyerInfoFormDescription>
        {watch("whom") === EWhomVariants.myself
          ? "Расскажите немного о себе"
          : "Расскажите немного о том, кому хотели бы сделать подарок"}
      </BuyerInfoFormDescription>
      <BuyerInfoFormLineBlock $hasBottomMargins>
        <BuyerInfoFormColumnBlock>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Обязательное поле",
            }}
            render={({ field, fieldState: { error } }): React.ReactElement => (
              <>
                {error?.message && (
                  <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
                )}
                <BuyerInfoFormInput {...field} placeholder="Имя" />
              </>
            )}
          />
        </BuyerInfoFormColumnBlock>
        <BuyerInfoFormColumnBlock>
          <Controller
            control={control}
            name="surname"
            render={({ field, fieldState: { error } }): React.ReactElement => (
              <>
                {error?.message && (
                  <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
                )}
                <BuyerInfoFormInput {...field} placeholder="Фамилия (Необязательно)" />
              </>
            )}
          />
        </BuyerInfoFormColumnBlock>
      </BuyerInfoFormLineBlock>
      <Controller
        control={control}
        name="email"
        rules={{
          required: "Обязательное поле",
          pattern: {
            value: emailRegexp,
            message: "Неверный формат",
          },
        }}
        render={({ field, fieldState: { error } }): React.ReactElement => (
          <>
            {error?.message && (
              <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
            )}
            <BuyerInfoFormInput
              {...field}
              type="email"
              placeholder={
                watch("whom") === EWhomVariants.myself
                  ? "Электронная почта"
                  : "Электронная почта получателя"
              }
              style={{ marginBottom: indents.i8 }}
            />
          </>
        )}
      />
      {watch("whom") === EWhomVariants.someone && (
        <>
          <Controller
            control={control}
            name="buyerEmail"
            rules={{
              required: "Обязательное поле",
              pattern: {
                value: emailRegexp,
                message: "Неверный формат",
              },
            }}
            render={({ field, fieldState: { error } }): React.ReactElement => (
              <>
                {error?.message && (
                  <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
                )}
                <BuyerInfoFormInput
                  {...field}
                  type="email"
                  placeholder="Ваша электронная почта"
                  style={{ marginBottom: indents.i8 }}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="fromWhom"
            render={({ field, fieldState: { error } }): React.ReactElement => (
              <>
                {error?.message && (
                  <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
                )}
                <BuyerInfoFormInput
                  {...field}
                  placeholder="От кого (необязательно)"
                  style={{ marginBottom: indents.i8 }}
                />
              </>
            )}
          />
          <Controller
            control={control}
            name="message"
            render={({ field, fieldState: { error } }): React.ReactElement => (
              <>
                {error?.message && (
                  <PaymentFormFieldError type="span">{error.message}</PaymentFormFieldError>
                )}
                <BuyerInfoFormInput {...field} placeholder="Текст сообщения (необязательно)" />
              </>
            )}
          />
        </>
      )}
    </BuyerInfoFormStyled>
  );
};

export default BuyerInfoForm;
