import styled from "@emotion/styled";
import indents from "../../../../shared/styles/indents";
import screens from "../../../../shared/styles/screens";
import Typography from "../../../../shared/components/Typography/Typography";
import Button from "../../../../shared/components/Button/Button";

export const CalendarInfoStyled = styled.div`
  width: calc(100% - (100% - 1150px));
  padding: ${indents.i24} calc((100% - 1150px) / 2) ${indents.i32} calc((100% - 1150px) / 2);

  @media all and (max-width: ${screens.lg}) {
    width: calc(100% - ${indents.i48});
    padding: ${indents.i96} ${indents.i24} ${indents.i32} ${indents.i24};
  }
`;

export const CalendarInfoTitle = styled(Typography)`
  padding-bottom: ${indents.i0};

  @media all and (max-width: ${screens.md}) {
    font-size: 28px;
  }
`;

export const CalendarInfoText = styled(Typography)`
  width: fit-content;

  span {
    display: block;
    width: 100%;
    margin-top: ${indents.i8};
    text-align: right;
  }
`;

export const CalendarDaysCardsList = styled.div`
  margin-top: ${indents.i16};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: ${indents.i16};

  @media all and (max-width: ${screens.lg}) {
    grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    justify-content: space-between;
  }

  @media all and (max-width: ${screens.md}) {
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
`;

export const CalendarInfoAction = styled(Button)`
  padding-left: ${indents.i32};
  padding-right: ${indents.i32};
  margin-top: ${indents.i16};
`;
