import styled from "@emotion/styled";
import colors from "../../../shared/styles/colors";
import screens from "../../../shared/styles/screens";

export const MainPageStyled = styled.div`
  width: 100%;
  height: 100vh;
`;

export const MainPageContent = styled.div`
  width: 100%;
  // 100% экрана - шапка
  height: calc(100% - 82px);
  background: ${colors.purple.d6};
  overflow-y: auto;

  @media all and (max-width: ${screens.l}) {
    height: 100%;
  }
`;

export const MainPageCarouselContainer = styled.div`
  width: 100%;
  height: 480px;

  @media all and (max-width: ${screens.l}) {
    height: 680px;
  }

  @media all and (max-width: ${screens.md}) {
    height: 740px;
  }
`;
