import { AxiosResponse } from "axios";
import { $calendarApi } from "../../../app/http";
import IPaymentForm from "../../../widgets/Calendar/CalendarPaymentModal/model/interfaces/IPaymentForm";
import IConfirmCalendarPaymentBody from "../interfaces/IConfirmCalendarPaymentBody";
import EWhomVariants from "../../../widgets/Calendar/CalendarPaymentModal/model/enums/EWhomVariants";

export const saveUserApplicationForPaymentCalendarRequest = (
  paymentForm: IPaymentForm,
): Promise<AxiosResponse<IConfirmCalendarPaymentBody>> => {
  const body: {
    whom: EWhomVariants;
    name: string;
    surname?: string;
    buyerInfo?: string;
    info: string;
    type: string;
    fromWhom: string | null;
    message: string | null;
  } = {
    whom: paymentForm.whom,
    name: paymentForm.name,
    surname: paymentForm.surname,
    buyerInfo:
      paymentForm.whom === EWhomVariants.myself ? paymentForm.email : paymentForm.buyerEmail,
    info: paymentForm.email,
    type: "EMAIL",
    fromWhom: paymentForm.whom === EWhomVariants.someone ? paymentForm.fromWhom : null,
    message: paymentForm.whom === EWhomVariants.someone ? paymentForm.message : null,
  };

  return $calendarApi.post<IConfirmCalendarPaymentBody>("/advent/order/create", body);
};
