import styled from "@emotion/styled";
import indents from "../../../styles/indents";
import Typography from "../../Typography/Typography";
import colors from "../../../styles/colors";
import borders from "../../../styles/borders";

export const AlertStyled = styled.div<{ $primary: string; $timeout: number }>`
  max-width: 300px;
  padding: ${indents.i12} ${indents.i16} ${indents.i16} ${indents.i16};
  border-radius: ${borders.r2};
  background: ${(props): string => props.$primary};
  animation: fadeInOut ${(props): string => `${props.$timeout}ms`} linear forwards;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    5%,
    80% {
      opacity: 1;
    }
  }
`;

export const AlertHeader = styled.div<{ $secondary: string }>`
  width: 100%;
  margin-bottom: ${indents.i8};
  display: flex;
  align-items: flex-end;

  svg {
    font-size: 24px;
    color: ${(props): string => props.$secondary};
    margin-right: ${indents.i4};
  }
`;

export const AlertTitle = styled(Typography)`
  padding: ${indents.i0};
  color: ${colors.white.d1};
`;

export const AlertBody = styled(Typography)`
  padding: ${indents.i0};
  color: ${colors.white.d1};
  font-size: 16px;
`;
