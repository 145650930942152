import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import {
  CarouselControls,
  CarouselControlsContainer,
  CarouselItem,
  CarouselItemsList,
  CarouselStyled,
} from "./carousel.styled";
import ICarouselProps from "../model/interfaces/ICarouselProps";
import LeftRightCarouselButtons from "./components/controls/LeftRightCarouselButtons/ui/LeftRightCarouselButtons";

const Carousel: React.FC<ICarouselProps> = ({ items, setActiveItem, activeItem }) => {
  /**
   * REFS
   */
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouselItemsListRef = useRef<HTMLDivElement>(null);

  /**
   * STATE
   */
  const [activeSlideItem, setActiveSlideItem] = useState<number>(activeItem || 0);

  /**
   * HANDLERS
   */
  const setCurrentSlide = (): void => {
    if (!carouselItemsListRef.current || !carouselRef.current) {
      return;
    }

    carouselItemsListRef.current.scrollTo({
      left: carouselRef.current.offsetWidth * activeSlideItem,
    });
  };

  const setCurrentSlideDebounce = debounce(setCurrentSlide, 500);

  /**
   * EFFECTS
   */
  useEffect(() => {
    // SAVE SLIDE POSITION BY RESIZE
    window.addEventListener("resize", setCurrentSlideDebounce);

    return () => {
      window.removeEventListener("resize", setCurrentSlideDebounce);
    };
  }, []);

  useEffect(() => {
    setCurrentSlide();

    if (!setActiveItem) {
      return;
    }

    setActiveItem(activeSlideItem);
  }, [activeSlideItem]);

  useEffect(() => {
    if (activeItem === undefined || activeItem === activeSlideItem) {
      return;
    }

    setActiveSlideItem(activeItem);
  }, [activeItem]);

  return (
    <CarouselStyled ref={carouselRef}>
      <CarouselItemsList ref={carouselItemsListRef}>
        {items.map((item) => (
          <CarouselItem key={item.key}>{item.element}</CarouselItem>
        ))}
      </CarouselItemsList>
      <CarouselControls>
        <CarouselControlsContainer>
          <LeftRightCarouselButtons
            activeItem={activeSlideItem}
            setActiveItem={setActiveSlideItem}
            length={items.length}
          />
        </CarouselControlsContainer>
      </CarouselControls>
    </CarouselStyled>
  );
};

export default Carousel;
