import { createBrowserRouter, RouteObject } from "react-router-dom";
import { Router } from "@remix-run/router/dist/router";
import LoggedMain from "../../pages/LoggedMain/ui/LoggedMain";
import NotFoundPage from "../../pages/NotFoundPage/ui/NotFoundPage";
import ErrorPage from "../../pages/ErrorPage/ui/ErrorPage";
import MainPage from "../../pages/MainPage/ui/MainPage";
import HomePage from "../../pages/HomePage/ui/HomePage";

const loggedRoutes: RouteObject[] = [
  {
    path: "",
    element: <LoggedMain />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const loginRoutes: RouteObject[] = [
  {
    path: "",
    element: <MainPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const getAppRoutes = (authorized: boolean): Router =>
  createBrowserRouter(authorized ? loggedRoutes : loginRoutes);

export default getAppRoutes;
