import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import {
  ButtonsBlock,
  FindSpecialistButton,
  HeaderContent,
  HeaderStyled,
  Link,
  LinksContainer,
  LogoImg,
  MobileActionsContainer,
  MobileButtonsBlock,
  MobileCloseHeaderButton,
  MobileHeader,
  MobileHeaderContainer,
  MobileLink,
  MobileLinksContainer,
  MobileLogo,
  MobileLogoImg,
  MobileLogoImgContainer,
  MobileShowHeaderButton,
  SocialItem,
  SocialList,
} from "./header.styled";
import logoImg from "../../../shared/assets/images/DOT-brandsign-b-logo.png";
import headerLinks from "../model/constants/headerLinks";
import EHeaderLink from "../model/enums/EHeaderLink";
import SubMenu from "./components/SubMenu/SubMenu";
import headerSocialItems from "../model/constants/headerSocialItems"; // import { useAppSelector } from "../../../shared/hooks/redux";
import MobileSubMenu from "./components/MobileSubMenu/ui/MobileSubMenu";

const Header: React.FC = () => {
  /**
   * STATE
   */
  // const { user } = useAppSelector((state) => state.userState);
  const [showMobileHeader, setShowMobileHeader] = useState<boolean>(false);

  /**
   * HANDLERS
   */
  const navigateTo = (action: "login" | "poll" | "profile"): void => {
    window.location.href = `https://go.dotherapy.ru/${action}/`;
  };

  return (
    <>
      {/* DESKTOP */}
      <HeaderStyled>
        <HeaderContent>
          <LogoImg src={logoImg} />
          <LinksContainer>
            {headerLinks
              .filter((item) => !item.onlyMobile)
              .map((headerLink) =>
                headerLink.type === EHeaderLink.MENU ? (
                  <SubMenu key={headerLink.label} headerLink={headerLink} />
                ) : (
                  <Link key={headerLink.label} href={headerLink.link}>
                    {headerLink.label}
                  </Link>
                ),
              )}
          </LinksContainer>
          <SocialList>
            {headerSocialItems.map((item) => (
              <SocialItem key={item.link} href={item.link}>
                {item.icon}
              </SocialItem>
            ))}
          </SocialList>
          <ButtonsBlock>
            <FindSpecialistButton appearance="text" onClick={(): void => navigateTo("poll")}>
              Подобрать специалиста
            </FindSpecialistButton>
            {/* <AccountButton */}
            {/*  appearance="secondary" */}
            {/*  onClick={(): void => navigateTo(user ? "profile" : "login")} */}
            {/* > */}
            {/*  {user ? "Пользователь" : "Войти"} */}
            {/* </AccountButton> */}
          </ButtonsBlock>
        </HeaderContent>
      </HeaderStyled>

      {/* MOBILE */}
      <MobileActionsContainer>
        <MobileLogo src={logoImg} />
        <MobileShowHeaderButton onClick={(): void => setShowMobileHeader(true)}>
          <FiMenu />
        </MobileShowHeaderButton>
      </MobileActionsContainer>
      <MobileHeader $open={showMobileHeader}>
        <MobileHeaderContainer>
          <MobileCloseHeaderButton onClick={(): void => setShowMobileHeader(false)} />
          <MobileLogoImgContainer>
            <MobileLogoImg src={logoImg} alt="DoTherapy" />
          </MobileLogoImgContainer>
          <MobileLinksContainer>
            {headerLinks.map((headerLink) =>
              headerLink.type === EHeaderLink.MENU ? (
                <MobileSubMenu key={headerLink.label} headerLink={headerLink} />
              ) : (
                <MobileLink key={headerLink.label} href={headerLink.link}>
                  {headerLink.label}
                </MobileLink>
              ),
            )}
          </MobileLinksContainer>
          <SocialList>
            {headerSocialItems.map((item) => (
              <SocialItem key={item.link} href={item.link}>
                {item.icon}
              </SocialItem>
            ))}
          </SocialList>
          <MobileButtonsBlock>
            <FindSpecialistButton
              $mobile
              appearance="light-purple"
              onClick={(): void => navigateTo("poll")}
            >
              Начать подбор
            </FindSpecialistButton>
            {/* <AccountButton */}
            {/*  appearance="secondary" */}
            {/*  onClick={(): void => navigateTo(user ? "profile" : "login")} */}
            {/* > */}
            {/*  {user ? "Пользователь" : "Войти"} */}
            {/* </AccountButton> */}
          </MobileButtonsBlock>
        </MobileHeaderContainer>
      </MobileHeader>
    </>
  );
};

export default Header;
