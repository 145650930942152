import dayjs, { Dayjs } from "dayjs";
import ITimeInfo from "../interfaces/ITimeInfo";
import store from "../../../../../../app/store";

const getCountdownByDate = (targetDate: Dayjs): ITimeInfo | undefined => {
  const { serverDateTime } = store.getState().appInfo;
  const duration = dayjs.duration(targetDate.diff(dayjs(serverDateTime)));

  if (duration.asMilliseconds() <= 0) return;

  return {
    hours: (duration.hours() + duration.days() * 24).toString().padStart(2, "0"),
    minutes: duration.minutes().toString().padStart(2, "0"),
    seconds: duration.seconds().toString().padStart(2, "0"),
  };
};

export default getCountdownByDate;
