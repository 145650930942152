import React from "react";
import {
  LeftCarouselButton,
  LeftCarouselIcon,
  RightCarouselButton,
  RightCarouselIcon,
} from "./leftRightCarouselButtons.styled";

const LeftRightCarouselButtons: React.FC<{
  activeItem: number;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  length: number;
}> = ({ activeItem, setActiveItem, length }) => {
  /**
   * HANDLERS
   */
  const setActiveItemHandler = (action: "left" | "right"): void => {
    if (action === "left") {
      if (activeItem === 0) {
        setActiveItem(length - 1);
        return;
      }

      setActiveItem((prev) => prev - 1);
    }

    if (action === "right") {
      if (activeItem === length - 1) {
        setActiveItem(0);
        return;
      }

      setActiveItem((prev) => prev + 1);
    }
  };

  return (
    <>
      {length > 1 && (
        <>
          <LeftCarouselButton onClick={(): void => setActiveItemHandler("left")}>
            <LeftCarouselIcon />
          </LeftCarouselButton>
          <RightCarouselButton onClick={(): void => setActiveItemHandler("right")}>
            <RightCarouselIcon />
          </RightCarouselButton>
        </>
      )}
    </>
  );
};

export default LeftRightCarouselButtons;
