import styled from "@emotion/styled";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import indents from "../../../../../../../styles/indents";
import transitions from "../../../../../../../styles/transitions";
import colors from "../../../../../../../styles/colors";

const LeftRightCarouselButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  outline: none;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  transition: ease-in-out ${transitions.t3};

  &:hover {
    background: ${colors.transparent.t95};
  }
`;

export const LeftCarouselButton = styled(LeftRightCarouselButton)`
  left: ${indents.i0};
`;

export const LeftCarouselIcon = styled(HiOutlineChevronLeft)`
  width: 28px;
  height: 28px;
`;

export const RightCarouselButton = styled(LeftRightCarouselButton)`
  right: ${indents.i0};
`;

export const RightCarouselIcon = styled(HiOutlineChevronRight)`
  width: 28px;
  height: 28px;
`;
