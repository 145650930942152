import styled from "@emotion/styled";
import colors from "../../../../../shared/styles/colors";
import transitions from "../../../../../shared/styles/transitions";
import Typography from "../../../../../shared/components/Typography/Typography";
import indents from "../../../../../shared/styles/indents";
import shadows from "../../../../../shared/styles/shadows";

export const SubMenuStyled = styled.div`
  position: relative;
  width: 100%;
  padding: ${indents.i12} ${indents.i0};
  cursor: pointer;

  &:hover {
    p {
      color: ${colors.white.d7};
    }

    .subMenuLinksContainer {
      display: block;
    }
  }
`;

export const SubMenuLabel = styled(Typography)`
  padding: ${indents.i0};
  color: ${colors.black.d9};
  transition: ease-in-out ${transitions.t3};
  font-family: "CoFo", serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: ${indents.i4};

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const SubMenuLinksContainer = styled.div`
  display: none;
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const SubMenuLinks = styled.div`
  position: relative;
  width: fit-content;
  padding: ${indents.i16} ${indents.i24};
  background: ${colors.white.d1};
  box-shadow: ${shadows.sh1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:before {
    content: "";
    border: 12px solid transparent;
    border-bottom: 12px solid ${colors.white.d1};
    position: absolute;
    left: 50%;
    margin-left: -12px;
    top: -24px;
    width: 0;
  }
`;

export const SubMenuLink = styled.a`
  width: 100%;
  padding: ${indents.i4} ${indents.i0};
  text-decoration: none;
  color: ${colors.black.d9};
  font-family: "CoFo", serif;
  font-size: 14px;
  transition: ease-in-out ${transitions.t3};

  &:hover {
    color: ${colors.white.d7};
  }
`;
