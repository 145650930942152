import React from "react";
import dayjs from "dayjs";
import {
  CalendarDayCardDate,
  CalendarDayCardImg,
  CalendarDayCardNumber,
  CalendarDayCardStyled,
  CalendarDayLockIcon,
} from "./calendarDayCard.styled";
import ICalendarDay from "../../../../../../../entities/calendar/interfaces/ICalendarDay";
import addPreZeroesForNumber from "../../../../../../../shared/helpers/addPreZeroesForNumber";

const CalendarDayCard: React.FC<
  { dayInfo: ICalendarDay } & React.HTMLAttributes<HTMLDivElement>
> = ({ dayInfo, ...attrs }) => {
  return (
    <CalendarDayCardStyled {...attrs} $whiteBackground={!!dayInfo.opened}>
      <CalendarDayCardNumber>{addPreZeroesForNumber(dayInfo.dayNumber)}</CalendarDayCardNumber>
      {dayInfo.opened ? (
        <CalendarDayCardImg src={`/calendarDays/${dayInfo.image}.png`} />
      ) : (
        <CalendarDayLockIcon />
      )}
      <CalendarDayCardDate type="span">
        {dayjs(dayInfo.targetDate).format("DD MMMM YYYY")}
      </CalendarDayCardDate>
    </CalendarDayCardStyled>
  );
};

export default CalendarDayCard;
