import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorPageContent, ErrorPageStyled } from "./errorPage.styled";
import Typography from "../../../shared/components/Typography/Typography";
import Button from "../../../shared/components/Button/Button";
import colors from "../../../shared/styles/colors";

const ErrorPage: React.FC = () => {
  /**
   * HELPERS
   */
  const navigate = useNavigate();

  return (
    <ErrorPageStyled>
      <ErrorPageContent>
        <Typography type="h3" color={colors.red.d1}>
          Возникла ошибка
        </Typography>
        <Button appearance="primary" onClick={(): void => navigate("/")}>
          На главную
        </Button>
      </ErrorPageContent>
    </ErrorPageStyled>
  );
};

export default ErrorPage;
