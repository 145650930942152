import React from "react";
import Header from "../../../widgets/Header/ui/Header";
import { MainPageCarouselContainer, MainPageContent, MainPageStyled } from "./mainPage.styled";
import Carousel from "../../../shared/components/Carousel/ui/Carousel";
import mainPageCarouselSlides from "../model/constants/mainPageCarouselSlides";
import CalendarInfo from "../../../widgets/Calendar/CalendarInfo/ui/CalendarInfo";
import AboutCalendar from "../../../widgets/Calendar/AboutCalendar/ui/AboutCalendar";

const MainPage: React.FC = () => {
  return (
    <MainPageStyled>
      <Header />
      <MainPageContent>
        <CalendarInfo />
        <MainPageCarouselContainer>
          <Carousel items={mainPageCarouselSlides} />
        </MainPageCarouselContainer>
        <AboutCalendar />
      </MainPageContent>
    </MainPageStyled>
  );
};

export default MainPage;
