import styled from "@emotion/styled";
import indents from "../../../../../shared/styles/indents";
import colors from "../../../../../shared/styles/colors";
import screens from "../../../../../shared/styles/screens";
import Typography from "../../../../../shared/components/Typography/Typography";

export const OpenAwaitSlideStyled = styled.div`
  width: calc(100% - (100% - 1150px));
  height: 100%;
  padding: ${indents.i0} calc((100% - 1150px) / 2);
  background: ${colors.white.d1};
  display: flex;
  align-items: flex-start;
  gap: ${indents.i48};

  @media all and (max-width: ${screens.lg}) {
    width: calc(100% - ${indents.i48});
    padding: ${indents.i0} ${indents.i24};
  }

  @media all and (max-width: ${screens.l}) {
    width: calc(100% - ${indents.i64});
    padding: ${indents.i0} ${indents.i32};
    display: grid;
    grid-template-areas:
      "dayNumber content"
      "image image";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;

    .dayNumber {
      grid-area: dayNumber;
    }

    .content {
      grid-area: content;
    }

    .image {
      grid-area: image;
    }
  }

  @media all and (max-width: ${screens.m}) {
    width: calc(100% - ${indents.i96});
    padding: ${indents.i0} ${indents.i48};
    grid-template-areas:
      "dayNumber"
      "content"
      "image";
    grid-template-columns: 1fr;
  }
`;

export const OpenAwaitSlideContent = styled.div`
  width: 40%;
  margin-top: ${indents.i48};
  display: flex;
  flex-direction: column;

  @media all and (max-width: ${screens.l}) {
    width: 100%;
  }

  @media all and (max-width: ${screens.m}) {
    margin-top: ${indents.i0};
    align-items: center;
  }
`;

export const OpenAwaitSlideTimeContainer = styled.div`
  position: absolute;
  top: 57%;
  left: 220px;

  p {
    padding: 0;
  }
`;

export const OpenAwaitSlideText = styled(Typography)`
  margin-bottom: ${indents.i32};
  padding: ${indents.i0};
`;

export const OpenAwaitSlideTime = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${indents.i4};
`;

export const OpenAwaitSlideTimeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding: ${indents.i0};
  }
`;
