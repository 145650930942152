import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  SelectWhomFormDescription,
  SelectWhomFormLineBlock,
  SelectWhomFormStyled,
  SelectWhomFormWhomVariant,
} from "./selectWhomForm.styled";
import whomVariants from "../../../../model/constants/whomVariants";

const SelectWhomForm: React.FC = () => {
  /**
   * STATE
   */
  const { control } = useFormContext();

  return (
    <SelectWhomFormStyled>
      <SelectWhomFormDescription>Кому вы хотели бы приобрести календарь?</SelectWhomFormDescription>
      <Controller
        name="whom"
        control={control}
        render={({ field: { onChange, value } }): React.ReactElement => (
          <SelectWhomFormLineBlock>
            {whomVariants.map((variant) => (
              <SelectWhomFormWhomVariant
                key={variant.key}
                appearance={value === variant.key ? "primary" : "secondary"}
                onClick={(): void => onChange(variant.key)}
              >
                {variant.label}
              </SelectWhomFormWhomVariant>
            ))}
          </SelectWhomFormLineBlock>
        )}
      />
    </SelectWhomFormStyled>
  );
};

export default SelectWhomForm;
