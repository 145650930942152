import styled from "@emotion/styled";
import colors from "../../../shared/styles/colors";
import screens from "../../../shared/styles/screens";

export const LoggedMainStyled = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;

export const LoggedMainContent = styled.div`
  width: 100%;
  // 100% экрана - шапка
  height: calc(100% - 82px);
  background: ${colors.purple.d6};
  overflow-y: auto;

  @media all and (max-width: ${screens.l}) {
    height: 100%;
  }
`;
