import IHeaderLink from "../interfaces/IHeaderLink";
import EHeaderLink from "../enums/EHeaderLink";

const headerLinks: IHeaderLink[] = [
  {
    type: EHeaderLink.MENU,
    label: "Форматы",
    sublinks: [
      {
        type: EHeaderLink.LINK,
        label: "Точка опоры",
        link: "https://dotherapy.ru/support_point",
      },
      {
        type: EHeaderLink.LINK,
        label: "Практикум «4 модальности»",
        link: "https://dotherapy.ru/modalnosti",
      },
    ],
  },
  {
    type: EHeaderLink.LINK,
    label: "О платформе",
    link: "https://dotherapy.ru/platforma",
  },
  {
    type: EHeaderLink.LINK,
    label: "Для бизнеса",
    link: "https://dotherapy.ru/b2b",
  },
  {
    type: EHeaderLink.LINK,
    label: "Блог",
    link: "https://dotherapy.ru/blog",
  },
  {
    type: EHeaderLink.LINK,
    label: "Частые вопросы",
    link: "https://dotherapy.ru/#voprosy",
    onlyMobile: true,
  },
];

export default headerLinks;
