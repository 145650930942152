import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "../../../../shared/components/Modal/Modal";
import {
  CalendarPaymentModalAction,
  CalendarPaymentModalBackStepButton,
  CalendarPaymentModalClose,
  CalendarPaymentModalContainer,
  CalendarPaymentModalStyled,
  CalendarPaymentModalTitle,
} from "./calendarPaymentModal.styled";
import IPaymentForm from "../model/interfaces/IPaymentForm";
import getInitPaymentFormState from "../model/constants/getInitPaymentFormState";
import paymentFormSteps from "../model/constants/paymentFormSteps";
import EPaymentFormSteps from "../model/enums/EPaymentFormSteps";
import { ICloudPayments } from "../../../../shared/interfaces/cloudPayments/ICloudPayments";
import env from "../../../../app/env/env";
import { saveUserApplicationForPaymentCalendarRequest } from "../../../../entities/user/api/saveUserApplicationForPaymentCalendarRequest";
import { confirmCalendarPaymentRequest } from "../../../../entities/user/api/confirmCalendarPaymentRequest";
import paymentErrors from "../model/constants/paymentErrors";
import sendYm from "../../../../shared/helpers/sendYm";
import EWhomVariants from "../model/enums/EWhomVariants";
import EPurchaseStatuses from "../../../../entities/user/enums/EPurchaseStatuses";

const CalendarPaymentModal: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  /**
   * STATE
   */
  const [paymentStep, setPaymentStep] = useState<EPaymentFormSteps>(EPaymentFormSteps.whom);
  const [failPaymentMessage, setFailPaymentMessage] = useState<string | null>(null);
  const form = useForm<IPaymentForm>();

  /**
   * HANDLERS
   */
  const setInitialFormData = (): void => {
    form.reset(getInitPaymentFormState(EPurchaseStatuses.notSubscribed));
    setPaymentStep(EPaymentFormSteps.whom);
  };

  const handleSubmit = (formState: IPaymentForm): void => {
    if (paymentStep === EPaymentFormSteps.whom) {
      sendYm(formState.whom === EWhomVariants.myself ? "advent_form_self" : "advent_form_gift");
      sendYm("advent_form_next");

      setPaymentStep(EPaymentFormSteps.buyerInfo);
      return;
    }

    if (paymentStep === EPaymentFormSteps.success) {
      setIsOpen(false);
      setInitialFormData();
      return;
    }

    if (paymentStep === EPaymentFormSteps.fail) {
      setInitialFormData();
      return;
    }

    sendYm("advent_form_gopay");
    saveUserApplicationForPaymentCalendarRequest(formState)
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const paymentWidget: ICloudPayments = new cp.CloudPayments();

        paymentWidget.pay(
          "charge",
          {
            publicId: env.cloudPaymentsID,
            description: "Адвент-календарь DoTherapy",
            amount: env.calendarAmount,
            currency: "RUB",
            invoiceId: res.data.invoiceId,
            email: formState.whom === EWhomVariants.myself ? formState.email : formState.buyerEmail,
            skin: "modern",
          },
          {
            onSuccess: () => {
              sendYm("advent_form_paid");

              confirmCalendarPaymentRequest(res.data)
                .then(() => {
                  setPaymentStep(EPaymentFormSteps.success);
                })
                .catch(() => {
                  setFailPaymentMessage(
                    "Платеж прошел успешно, но возникла ошибка при выдаче доступа. Пожалуйста, обратитесь в нашу службу поддержки и укажите электронную почту, которую вы указали ранее. Мы вам обязательно поможем",
                  );
                  setPaymentStep(EPaymentFormSteps.fail);
                });
            },
            onFail: (reason) => {
              setFailPaymentMessage(paymentErrors[reason]);
              setPaymentStep(EPaymentFormSteps.fail);
            },
          },
        );
      })
      .catch(() => {
        setFailPaymentMessage("Возникла ошибка на стадии создания платежа");
        setPaymentStep(EPaymentFormSteps.fail);
      });
  };

  /**
   * EFFECTS
   */
  useEffect(() => {
    setInitialFormData();
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} showCloseButton={false}>
      <CalendarPaymentModalStyled>
        <FormProvider {...form}>
          <CalendarPaymentModalContainer onSubmit={form.handleSubmit(handleSubmit)}>
            <CalendarPaymentModalClose onClick={(): void => setIsOpen(false)} />
            {paymentStep === EPaymentFormSteps.buyerInfo && (
              <CalendarPaymentModalBackStepButton
                onClick={(): void => setPaymentStep(EPaymentFormSteps.whom)}
              />
            )}
            <CalendarPaymentModalTitle type="h2">
              {paymentFormSteps[paymentStep].title}
            </CalendarPaymentModalTitle>
            {paymentFormSteps[paymentStep].element(failPaymentMessage)}
            <CalendarPaymentModalAction
              disabled={
                !form.formState.isValid &&
                paymentStep !== EPaymentFormSteps.success &&
                paymentStep !== EPaymentFormSteps.fail
              }
              type="submit"
            >
              {paymentFormSteps[paymentStep].actionText}
            </CalendarPaymentModalAction>
          </CalendarPaymentModalContainer>
        </FormProvider>
      </CalendarPaymentModalStyled>
    </Modal>
  );
};

export default CalendarPaymentModal;
