import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

interface UserState {
  authorized: boolean;
}

const initialState: UserState = {
  authorized: false,
};

const userAdapter = createEntityAdapter<UserState>();

const userSlice = createSlice({
  name: "user",
  initialState: userAdapter.getInitialState(initialState),
  reducers: {
    updateUserData: (state, action: PayloadAction<{ authorized: boolean }>) => {
      state.authorized = action.payload.authorized;
    },
    logout: (state) => {
      Cookies.remove("access_token");

      state.authorized = false;
    },
  },
});

export const { updateUserData, logout } = userSlice.actions;

export default userSlice.reducer;
