import React from "react";
import EPresentType from "../enums/EPresentType";
import { CalendarDayModalPresentDescription } from "../../../widgets/Calendar/CalendarDayModal/ui/calendarDayModal.styled";

const presentsInfo: { key: EPresentType; label: string; description: React.ReactNode }[] = [
  {
    key: EPresentType.halfPriceConsultation,
    label: "Скидка 50% на одну онлайн-консультацию с психологом платформы DoTherapy",
    description: (
      <CalendarDayModalPresentDescription>
        Ваш промокод PODAROK50.
        <br />
        Для его применения вам необходимо быть зарегистрированным на DoTerapy с адресом электронной
        почты, на которую оформлен адвент
        <br />
        <a href="https://go.dotherapy.ru/register/">Зарегистрироваться</a> /{" "}
        <a href="https://go.dotherapy.ru/login/">Войти</a>
      </CalendarDayModalPresentDescription>
    ),
  },
  {
    key: EPresentType.freeDereflexyGroup,
    label:
      "Научитесь видеть что-то хорошее в каждом дне: 1 бесплатное занятие в группе дерефлексии",
    description: (
      <CalendarDayModalPresentDescription>
        Напишите нам на <a href="mailto:sales@dotherapy.ru">sales@dotherapy.ru</a>, чтобы
        использовать этот подарок
      </CalendarDayModalPresentDescription>
    ),
  },
  {
    key: EPresentType.dreamAnalysis,
    label:
      "Познайте себя через сновидения: бесплатное посещение анализа сновидений методом группового психоанализа",
    description: (
      <CalendarDayModalPresentDescription>
        Напишите нам на <a href="mailto:sales@dotherapy.ru">sales@dotherapy.ru</a>, чтобы
        использовать этот подарок
      </CalendarDayModalPresentDescription>
    ),
  },
  {
    key: EPresentType.freePracticumMeeting,
    label: "1 бесплатная встреча на практикуме для психологов у Евгения Головинова",
    description: (
      <CalendarDayModalPresentDescription>
        Напишите нам на <a href="mailto:sales@dotherapy.ru">sales@dotherapy.ru</a>, чтобы
        использовать этот подарок
      </CalendarDayModalPresentDescription>
    ),
  },
];

export default presentsInfo;
