import styled from "@emotion/styled";
import { RiEmotionSadLine } from "react-icons/ri";
import colors from "../../../../../../../shared/styles/colors";
import Typography from "../../../../../../../shared/components/Typography/Typography";

export const FailCalendarPaymentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FailCalendarPaymentIcon = styled(RiEmotionSadLine)`
  font-size: 48px;
  color: ${colors.blue.d2};
`;

export const FailCalendarPaymentDescription = styled(Typography)`
  width: 100%;
  text-align: center;
`;
