import React, { useState } from "react";
import AlertContext from "../context/AlertContext";
import { AlertContainer, AlertProviderStyled } from "../styles/alertProvider.styled";
import IAlertProps from "../interfaces/IAlertProps";
import Alert from "../Alert";
import TAlertData from "../types/TAlertData";

const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [countFromInitial, setCountFromInitial] = useState(0);
  const [alerts, setAlerts] = useState<IAlertProps[]>([]);

  const addAlert = (alert: TAlertData): number => {
    const id = countFromInitial;
    setAlerts((prev) => [{ ...alert, id }, ...prev]);
    setCountFromInitial((prev) => prev + 1);
    return id;
  };

  const dismissAlert = (id: number): void => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AlertContext.Provider value={{ addAlert, dismissAlert }}>
      <AlertProviderStyled>
        {children}
        {!!alerts.length && (
          <AlertContainer>
            {alerts.map((alert) => (
              <Alert key={alert.id} {...alert} handleDismiss={(): void => dismissAlert(alert.id)} />
            ))}
          </AlertContainer>
        )}
      </AlertProviderStyled>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
