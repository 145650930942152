import React, { useContext, useEffect, useState } from "react";
import { LuTimer } from "react-icons/lu";
import { FaRegFilePdf } from "react-icons/fa6";
import { TfiGift } from "react-icons/tfi";
import {
  CalendarDayModalAction,
  CalendarDayModalClose,
  CalendarDayModalContainer,
  CalendarDayModalDownloadFile,
  CalendarDayModalGetPresentButton,
  CalendarDayModalNumber,
  CalendarDayModalPlayer,
  CalendarDayModalPresent,
  CalendarDayModalPresents,
  CalendarDayModalStyled,
  CalendarDayModalText,
} from "./calendarDayModal.styled";
import Modal from "../../../../shared/components/Modal/Modal";
import addPreZeroesForNumber from "../../../../shared/helpers/addPreZeroesForNumber";
import { getCalendarDayContentRequest } from "../../../../entities/calendar/api/getCalendarDayContentRequest";
import AlertContext from "../../../../shared/components/Alert/context/AlertContext";
import ICalendarDayContent from "../../../../entities/calendar/interfaces/ICalendarDayContent";
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks/redux";
import { updateDayInfo } from "../../../../entities/calendar/store/calendarSlice";
import presentsInfo from "../../../../entities/calendar/mock/presentsInfo";
import { fetchAddPresent } from "../../../../entities/calendar/store/calendarThunk";
import EPresentType from "../../../../entities/calendar/enums/EPresentType";
import Typography from "../../../../shared/components/Typography/Typography";

const CalendarDayModal: React.FC<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dayNumber: number;
}> = ({ isOpen, setIsOpen, dayNumber }) => {
  /**
   * HELPERS
   */
  const { addAlert } = useContext(AlertContext);
  const dispatch = useAppDispatch();

  /**
   * DATA
   */
  const { receivedPresents } = useAppSelector((state) => state.calendarState);
  const [dayContent, setDayContent] = useState<ICalendarDayContent | null>(null);

  /**
   * HANDLERS
   */
  const hasPresentInReceived = (presentType: EPresentType): boolean =>
    receivedPresents.some((present) => present.present === presentType);

  const addPresentHandler = (presentType: EPresentType): void => {
    dispatch(fetchAddPresent(presentType)).then((res) => {
      if (res.type === "appInfo/getServerDateTime/rejected") {
        addAlert({
          text: "Ошибка авторизации",
          timeout: 3000,
          status: "error",
        });
      }
    });
  };

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getCalendarDayContentRequest(dayNumber)
      .then((res) => {
        dispatch(updateDayInfo(dayNumber));
        setDayContent(res.data);
      })
      .catch(() => {
        setIsOpen(false);
        addAlert({
          text: "Возникла ошибка при получении контента",
          timeout: 3000,
          status: "error",
        });
      });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} showCloseButton={false}>
      {dayContent && (
        <CalendarDayModalStyled>
          <CalendarDayModalContainer>
            <CalendarDayModalClose onClick={(): void => setIsOpen(false)} />
            <CalendarDayModalNumber type="h1">
              {addPreZeroesForNumber(dayNumber)}
            </CalendarDayModalNumber>
            <CalendarDayModalText>{dayContent.text}</CalendarDayModalText>
            {dayContent.timeToComplete && (
              <CalendarDayModalAction>
                <LuTimer />
                Время выполнения упражнения — {dayContent.timeToComplete} минут
              </CalendarDayModalAction>
            )}
            {dayContent.mediaType === "VIDEO" && dayContent?.link && (
              <CalendarDayModalPlayer url={dayContent.link} controls />
            )}
            {dayContent.mediaType === "FILE" && dayContent?.link && (
              <CalendarDayModalDownloadFile as="a" href={dayContent.link} target="_blank">
                <FaRegFilePdf />
                {dayContent.buttonLabel || "Скачать"}
              </CalendarDayModalDownloadFile>
            )}
            {dayNumber === 14 && (
              <CalendarDayModalPresents>
                {presentsInfo.map((present) => (
                  <CalendarDayModalPresent key={present.key}>
                    <Typography>{present.label}</Typography>
                    {!hasPresentInReceived(present.key) ? (
                      <CalendarDayModalGetPresentButton
                        onClick={(): void => addPresentHandler(present.key)}
                      >
                        <TfiGift />
                        Получить подарок
                      </CalendarDayModalGetPresentButton>
                    ) : (
                      <>
                        <CalendarDayModalGetPresentButton disabled>
                          Подарок получен
                        </CalendarDayModalGetPresentButton>
                        {present.description}
                      </>
                    )}
                  </CalendarDayModalPresent>
                ))}
              </CalendarDayModalPresents>
            )}
          </CalendarDayModalContainer>
        </CalendarDayModalStyled>
      )}
    </Modal>
  );
};

export default CalendarDayModal;
