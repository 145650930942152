import styled from "@emotion/styled";
import colors from "../../../../shared/styles/colors";
import indents from "../../../../shared/styles/indents";
import Typography from "../../../../shared/components/Typography/Typography";
import screens from "../../../../shared/styles/screens";
import zIndexes from "../../../../shared/styles/zIndexes";

export const DayNumber = styled(Typography)`
  width: 58px;
  border-bottom: 4px solid ${colors.blue.d2};
  padding: ${indents.i48} ${indents.i0} ${indents.i0} ${indents.i0};
  font-weight: 400;
  text-align: center;

  @media all and (max-width: ${screens.md}) {
    z-index: ${zIndexes.topElements};
    align-self: flex-start;
    margin-right: -20px;
  }
`;

export const SlideImage = styled.div`
  flex-grow: 1;
  position: relative;
  height: 90%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  img:nth-child(1) {
    position: absolute;
    top: 0;
    left: 50px;
    height: 100%;
  }

  img:nth-child(2) {
    position: absolute;
    height: 45%;
    top: 45%;
    left: 190px;
  }

  @media all and (max-width: ${screens.calendarImageS1}) {
    img:nth-child(1) {
      left: 0;
    }

    img:nth-child(2) {
      left: 150px;
    }
  }

  @media all and (max-width: ${screens.l}) {
    height: 260px;
    border-top: 2px solid ${colors.transparent.t75};

    img:nth-child(1) {
      height: 100%;
      left: -50px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    img:nth-child(2) {
      left: -10px;
      right: -20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media all and (max-width: ${screens.md}) {
    width: 100%;
    height: 320px;
    border-top: none;

    img:nth-child(1) {
      left: -30px;
      right: 0;
    }

    img:nth-child(2) {
      top: 135px;
      left: 40px;
      right: 0;
    }
  }

  @media all and (max-width: ${screens.m}) {
    height: 290px;

    img:nth-child(1) {
      left: -30px;
      right: 0;
    }

    img:nth-child(2) {
      top: 125px;
      left: 30px;
      right: 0;
    }
  }
`;
