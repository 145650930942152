import styled from "@emotion/styled";
import indents from "../../../../shared/styles/indents";
import screens from "../../../../shared/styles/screens";
import Typography from "../../../../shared/components/Typography/Typography";
import colors from "../../../../shared/styles/colors";
import borders from "../../../../shared/styles/borders";
import Button from "../../../../shared/components/Button/Button";

export const AboutCalendarStyled = styled.div`
  width: calc(100% - (100% - 1150px));
  padding: ${indents.i24} calc((100% - 1150px) / 2) ${indents.i32} calc((100% - 1150px) / 2);

  @media all and (max-width: ${screens.lg}) {
    width: calc(100% - ${indents.i48});
    padding: ${indents.i24} ${indents.i24} ${indents.i32} ${indents.i24};
  }
`;

export const AboutCalendarHelpersInfo = styled.div`
  width: 100%;
`;

export const AboutCalendarHelpersInfoText = styled(Typography)`
  padding: ${indents.i32} ${indents.i0};
  text-align: center;
`;

export const AboutCalendarHelpersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${indents.i12};
`;

export const AboutCalendarHelpersListItem = styled.div`
  width: calc(100% - ${indents.i24});
  padding: ${indents.i8} ${indents.i12};
  background: ${colors.white.d1};
  border-radius: ${borders.full};
  display: flex;
  align-items: center;
  gap: ${indents.i12};

  @media all and (max-width: ${screens.m}) {
    border-radius: ${borders.r3};
    align-items: flex-start;
  }
`;

export const AboutCalendarHelpersListItemIndex = styled.div`
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  background: ${colors.purple.d5};
  border-radius: ${borders.full};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AboutCalendarActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AboutCalendarBuyButton = styled(Button)`
  padding-left: ${indents.i48};
  padding-right: ${indents.i48};
`;

export const AboutCalendarGreetingText = styled(Typography)`
  width: 100%;
  margin-top: ${indents.i16};
  color: ${colors.blue.d2};
  text-align: center;
`;
