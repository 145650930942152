import axios, { InternalAxiosRequestConfig } from "axios";
import env from "../env/env";
import store from "../store";
import { logout } from "../../entities/user/store/userSlice";

// CALENDAR API
export const $calendarApi = axios.create({
  baseURL: env.calendarApi,
});

$calendarApi.interceptors.request.use(
  (config) => {
    return {
      ...config,
      withCredentials: true,
    } as InternalAxiosRequestConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

$calendarApi.interceptors.response.use((res) => {
  if (res.status === 403) {
    store.dispatch(logout());
  }

  return res;
});
