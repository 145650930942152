import React, { useState } from "react";
import {
  AboutCalendarActions,
  AboutCalendarBuyButton,
  AboutCalendarGreetingText,
  AboutCalendarHelpersInfo,
  AboutCalendarHelpersInfoText,
  AboutCalendarHelpersList,
  AboutCalendarHelpersListItem,
  AboutCalendarHelpersListItemIndex,
  AboutCalendarStyled,
} from "./aboutCalendar.styled";
import helpersItems from "../model/constants/helpersItems";
import Typography from "../../../../shared/components/Typography/Typography";
import CalendarPaymentModal from "../../CalendarPaymentModal/ui/CalendarPaymentModal";
import sendYm from "../../../../shared/helpers/sendYm";

const AboutCalendar: React.FC = () => {
  /**
   * STATE
   */
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState<boolean>(false);

  /**
   * HANDLERS
   */
  const setIsOpenPaymentModalHandler = (): void => {
    sendYm("advent_button_buy");
    setIsOpenPaymentModal(true);
  };

  return (
    <>
      <AboutCalendarStyled>
        <AboutCalendarHelpersInfo>
          <AboutCalendarHelpersInfoText>
            С Адвент-календарем от DoTherapy вас ждут 2 недели погружения в психологические техники
            от лучших мастеров, которые помогут:
          </AboutCalendarHelpersInfoText>
          <AboutCalendarHelpersList>
            {helpersItems.map((item, index) => (
              <AboutCalendarHelpersListItem key={item}>
                <AboutCalendarHelpersListItemIndex>
                  <Typography type="b">{index + 1}</Typography>
                </AboutCalendarHelpersListItemIndex>
                {item}
              </AboutCalendarHelpersListItem>
            ))}
          </AboutCalendarHelpersList>
          <AboutCalendarHelpersInfoText>
            После покупки адвент-календаря вы получите письмо с доступом 17 декабря, а первое окошко
            откроется 18 декабря.
          </AboutCalendarHelpersInfoText>
          <AboutCalendarActions>
            <AboutCalendarBuyButton onClick={setIsOpenPaymentModalHandler}>
              Купить
            </AboutCalendarBuyButton>
          </AboutCalendarActions>
          <AboutCalendarGreetingText type="h3">
            С наступающим Новым годом!
          </AboutCalendarGreetingText>
        </AboutCalendarHelpersInfo>
      </AboutCalendarStyled>
      <CalendarPaymentModal isOpen={isOpenPaymentModal} setIsOpen={setIsOpenPaymentModal} />
    </>
  );
};

export default AboutCalendar;
