import styled from "@emotion/styled";
import { IoArrowBack, IoCloseOutline } from "react-icons/io5";
import Typography from "../../../../shared/components/Typography/Typography";
import indents from "../../../../shared/styles/indents";
import colors from "../../../../shared/styles/colors";
import borders from "../../../../shared/styles/borders";
import screens from "../../../../shared/styles/screens";
import Button from "../../../../shared/components/Button/Button";

export const CalendarPaymentModalStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CalendarPaymentModalContainer = styled.form`
  position: relative;
  width: calc(45% - ${indents.i64});
  padding: ${indents.i32};
  background: ${colors.white.d1};
  border-radius: ${borders.r4};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: ${screens.lg}) {
    width: calc(60% - ${indents.i64});
  }

  @media all and (max-width: ${screens.m}) {
    width: calc(90% - ${indents.i64});
  }
`;

export const CalendarPaymentModalClose = styled(IoCloseOutline)`
  position: absolute;
  width: 32px;
  height: 32px;
  top: ${indents.i16};
  right: ${indents.i16};
  cursor: pointer;
`;

export const CalendarPaymentModalBackStepButton = styled(IoArrowBack)`
  position: absolute;
  width: 32px;
  height: 32px;
  top: ${indents.i16};
  left: ${indents.i16};
  cursor: pointer;
`;

export const CalendarPaymentModalTitle = styled(Typography)`
  text-align: center;
`;

export const CalendarPaymentModalAction = styled(Button)`
  padding-left: ${indents.i32};
  padding-right: ${indents.i32};
  margin-top: ${indents.i32};
`;
