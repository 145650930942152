import React from "react";
import dayjs from "dayjs";
import { HomePageStyled } from "./homePage.styled";
import CalendarInfo from "../../../widgets/Calendar/CalendarInfo/ui/CalendarInfo";
import { useAppSelector } from "../../../shared/hooks/redux";
import AboutCalendar from "../../../widgets/Calendar/AboutCalendar/ui/AboutCalendar";
import HomePageCarousel from "../../../widgets/HomePage/HomePageCarousel/ui/HomePageCarousel";

const HomePage: React.FC = () => {
  /**
   * CONSTANTS
   */
  const openCalendarDate = dayjs("12/10/2023");

  /**
   * STATE
   */
  const { authorized } = useAppSelector((state) => state.userState);

  return (
    <HomePageStyled>
      <CalendarInfo openCalendarDate={openCalendarDate} />
      <HomePageCarousel openCalendarDate={openCalendarDate} />
      {!authorized && <AboutCalendar />}
    </HomePageStyled>
  );
};

export default HomePage;
