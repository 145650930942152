import styled from "@emotion/styled";
import { IoCloseOutline } from "react-icons/io5";
import indents from "../../../shared/styles/indents";
import colors from "../../../shared/styles/colors";
import transitions from "../../../shared/styles/transitions";
import borders from "../../../shared/styles/borders";
import Button from "../../../shared/components/Button/Button";
import screens from "../../../shared/styles/screens";
import zIndexes from "../../../shared/styles/zIndexes";

export const HeaderStyled = styled.header`
  width: calc(100% - ${indents.i48});
  height: 50px;
  padding: ${indents.i16} ${indents.i24};
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (max-width: ${screens.l}) {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  max-width: 1150px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 120px;
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${indents.i0} ${indents.i24};
  column-gap: ${indents.i24};
`;

export const Link = styled.a`
  font-family: "CoFo", serif;
  padding: ${indents.i0};
  text-decoration: none;
  color: ${colors.black.d9};
  transition: ease-in-out ${transitions.t3};
  white-space: nowrap;

  &:hover {
    color: ${colors.white.d7};
  }
`;

export const SocialList = styled.div`
  display: flex;
  align-items: center;
  gap: ${indents.i8};
`;

export const SocialItem = styled.a`
  width: 30px;
  aspect-ratio: 1 / 1;
  text-decoration: none;
  background: ${colors.blue.d2};
  border-radius: ${borders.full};
  font-size: 17px;
  color: ${colors.white.d1};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:has(img) {
    background: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${indents.i12};
`;

export const FindSpecialistButton = styled(Button)<{ $mobile?: boolean }>`
  width: ${(props): string => (props.$mobile ? "fit-content" : "auto")};
  padding: ${indents.i12} ${(props): string => (props.$mobile ? indents.i24 : indents.i32)};
  font-family: "CoFo", serif;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

export const AccountButton = styled(Button)`
  font-family: "CoFo", serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px ${indents.i32};
`;

// MOBILE
export const MobileActionsContainer = styled.div`
  display: none;
  position: fixed;
  z-index: ${zIndexes.popup};
  width: calc(100% - ${indents.i48});
  padding: ${indents.i16} ${indents.i24};
  background: ${colors.purple.d6};
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: ${screens.l}) {
    display: flex;
  }
`;

export const MobileLogo = styled.img`
  display: none;
  height: 48px;

  @media all and (max-width: ${screens.l}) {
    display: block;
  }
`;

export const MobileShowHeaderButton = styled(Button)`
  display: none;
  width: 48px;
  height: 48px;

  svg {
    width: 100%;
    height: 100%;
    padding: ${indents.i0};
  }

  @media all and (max-width: ${screens.l}) {
    display: block;
  }
`;

export const MobileHeader = styled.div<{ $open: boolean }>`
  display: none;
  position: absolute;
  z-index: ${zIndexes.popup};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${colors.transparent.t50};
  justify-content: flex-end;

  @media all and (max-width: ${screens.l}) {
    display: ${(props): string => (props.$open ? "flex" : "none")};
  }
`;

export const MobileHeaderContainer = styled.div`
  width: 184px;
  height: calc(100% - 86px);
  padding: ${indents.i48} 38px 38px;
  background: ${colors.white.d1};
  display: flex;
  flex-direction: column;
`;

export const MobileCloseHeaderButton = styled(IoCloseOutline)`
  position: absolute;
  top: ${indents.i8};
  right: ${indents.i8};
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const MobileLogoImgContainer = styled.div`
  width: 80%;
`;

export const MobileLogoImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const MobileLinksContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${indents.i16};
  padding: ${indents.i24} ${indents.i0};
`;

export const MobileLink = styled.a`
  padding: ${indents.i0};
  text-decoration: none;
  color: ${colors.black.d9};
  transition: ease-in-out ${transitions.t3};
  white-space: nowrap;
  font-family: "CoFo", serif;
  font-size: 22px;

  &:hover {
    color: ${colors.white.d7};
  }
`;

export const MobileButtonsBlock = styled.div`
  margin-top: ${indents.i24};
  display: flex;
  flex-direction: column;
  gap: ${indents.i16};
`;
