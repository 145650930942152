import React from "react";
import TTypographyType from "./types/TTypographyType";
import { TypographyStyled } from "./styles/typography.styled";

const Typography: React.FC<
  {
    type?: TTypographyType;
    children?: React.ReactNode;
    elementRef?: React.RefObject<HTMLSpanElement>;
  } & React.HTMLAttributes<HTMLSpanElement>
> = ({ type = "p", children, elementRef, ...attrs }) => {
  return (
    <TypographyStyled {...attrs} as={type} ref={elementRef}>
      {children}
    </TypographyStyled>
  );
};

export default Typography;
