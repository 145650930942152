import React from "react";
import dayjs, { Dayjs } from "dayjs";
import Carousel from "../../../../shared/components/Carousel/ui/Carousel";
import { HomePageCarouselStyled } from "./homePageCarousel.styled";
import TimerSlide from "../../../Calendar/CalendarSlides/TimerSlide/ui/TimerSlide";
import OpenAwaitSlide from "../../../Calendar/CalendarSlides/OpenAwaitSlide/ui/OpenAwaitSlide";
import AboutSlide from "../../../Calendar/CalendarSlides/AboutSlide/ui/AboutSlide";
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks/redux";
import { setActiveTimeSlide } from "../../../../entities/calendar/store/calendarSlice";

const HomePageCarousel: React.FC<{ openCalendarDate: Dayjs }> = ({ openCalendarDate }) => {
  /**
   * HELPERS
   */
  const dispatch = useAppDispatch();

  /**
   * STATE
   */
  const {
    appInfo: { serverDateTime },
    userState: { authorized },
    calendarState: { daysInfo, activeTimeSlide },
  } = useAppSelector((state) => state);

  /**
   * HANDLERS
   */
  const setActiveTimeSlideHandler = (): void => {
    dispatch(setActiveTimeSlide(activeTimeSlide));
  };

  if (authorized) {
    if (openCalendarDate.isBefore(serverDateTime)) {
      return (
        <HomePageCarouselStyled>
          <Carousel
            items={daysInfo.map((day) => ({
              key: day.dayNumber,
              element: (
                <TimerSlide
                  number={day.dayNumber}
                  text={day.text}
                  targetDate={dayjs(day.targetDate)}
                  image={day.image}
                  openedDay={day.opened}
                />
              ),
            }))}
            activeItem={activeTimeSlide}
            setActiveItem={setActiveTimeSlideHandler}
          />
        </HomePageCarouselStyled>
      );
    }

    return (
      <HomePageCarouselStyled>
        <Carousel
          items={[
            {
              key: 1,
              element: <OpenAwaitSlide openCalendarDate={openCalendarDate} />,
            },
          ]}
        />
      </HomePageCarouselStyled>
    );
  }

  return (
    <HomePageCarouselStyled>
      <Carousel
        items={[
          {
            key: 1,
            element: <AboutSlide />,
          },
        ]}
      />
    </HomePageCarouselStyled>
  );
};

export default HomePageCarousel;
