const colors = {
  transparent: {
    t25: "rgba(0,0,0,0.75)",
    t50: "rgba(0,0,0,0.50)",
    t75: "rgba(0,0,0,0.25)",
    t95: "rgba(0,0,0,0.05)",
    t100: "rgba(0,0,0,0)",
  },
  white: {
    d1: "#FFFFFF",
    d2: "#F4F4F6",
    d3: "#E8E8E8",
    d4: "#DCDCDE",
    d5: "#D9D9D9",
    d6: "#D9D8E0",
    d7: "#A5A5A5",
    d8: "#666666",
    d9: "#636363",
    d10: "rgba(165, 165, 165, 0.3)",
    d11: "#F2F3F9",
    g1: "linear-gradient(0deg, #DCDCDE, #DCDCDE), #311CA9",
  },
  purple: {
    d1: "#311CA9",
    d2: "#2c1998",
    d3: "#5E3FE8",
    d4: "#7545C9",
    d5: "#cebafc",
    d6: "#ebe5ff",
    d7: "#e1e3ee",
    g1: "linear-gradient(0deg, #7545C9, #311CA9), #311CA9",
    g2: "linear-gradient(90deg, #311ca9 0%, #82b1ff 104.57%),linear-gradient(270deg, #f92f43 -107.29%, rgba(249, 47, 67, 0) 100%)",
    g3: "linear-gradient(0deg, #5E3FE8 0%, #5E3FE8 100%), #F4F4F6",
  },
  red: {
    d1: "#FB012D",
    d2: "#E80A29",
  },
  yellow: {
    d1: "#F4EB0B",
    d2: "#fffa5a",
  },
  green: {
    d1: "#ACE70A",
    d2: "#bae34a",
  },
  black: {
    d7: "#00000099",
    d8: "#000000CC",
    d9: "#000000",
  },
  blue: {
    d1: "#321DAA",
    d2: "#3C00FF",
    d3: "rgba(56,0,255,0.9)",
    d4: "#82B1FF",
  },
};

export default colors;
