import React from "react";
import "./styles/index.css";
import { RouterProvider } from "react-router-dom";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import dayjsIsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjsUtc from "dayjs/plugin/utc";
import dayjsTimezone from "dayjs/plugin/timezone";
import "dayjs/locale/ru";
import { initI18next } from "../shared/locale/init";
import getAppRoutes from "./routes";
import { useAppSelector } from "../shared/hooks/redux";
import useInitApp from "./hooks/useInitApp";
import AlertProvider from "../shared/components/Alert/providers/alertProvider";

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.locale("ru");
initI18next();

const App: React.FC = () => {
  const { authorized } = useAppSelector((state) => state.userState);
  const routes = getAppRoutes(authorized);

  useInitApp();

  return (
    <AlertProvider>
      <RouterProvider router={routes} />
    </AlertProvider>
  );
};

export default App;
