import styled from "@emotion/styled";
import TTypographyType from "../types/TTypographyType";
import colors from "../../../styles/colors";
import typographyStylesInfo from "../constants/typographyStylesInfo";
import indents from "../../../styles/indents";

export const TypographyStyled = styled.span<{ as: TTypographyType }>((props) => ({
  fontFamily: "Gilroy, serif",
  color: props.color || colors.black.d9,
  margin: indents.i0,
  // CSSProperty: ts ругается на тип, несовместимость css и styled components
  ...(typographyStylesInfo[props.as as TTypographyType] as any),
}));
