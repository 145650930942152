import styled from "@emotion/styled";
import Button from "../../../shared/components/Button/Button";
import indents from "../../../shared/styles/indents";

export const NotFoundPageStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotFoundPageContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 380px;
  }

  h3 {
    text-align: center;
  }
`;

export const NotFoundPageBackButton = styled(Button)`
  padding-left: ${indents.i48};
  padding-right: ${indents.i48};
`;
