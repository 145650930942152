import ICalendarDay from "../interfaces/ICalendarDay";

const daysInfo: ICalendarDay[] = [
  {
    dayNumber: 1,
    text:
      "Неопределенность… Как же часто мы с ней сталкиваемся!\n" +
      "И, как правило, мы её боимся и чувствуем себя застигнутыми врасплох из-за возникших обстоятельств, которые не получилось предсказать заранее и проконтролировать.\n" +
      "Но что, если мы вам скажем, что в неопределенности есть и плюсы? Не верите?\n" +
      "\n" +
      "Наш эксперт попробует вас переубедить, ведь логотерапия доказывает, что даже в самые темные времена можно найти луч света. \n",
    targetDate: "12/18/2023",
    image: "Decoration-1",
  },
  {
    dayNumber: 2,
    text:
      "Пришло время подвести итоги!\n" +
      "Мы уверены, что в вашем 2023 году было множество разных событий и предлагаем вам их вспомнить.\n" +
      "\n" +
      "При клике на кнопку «Открыть день» вы увидите наш шаблон для заполнения. Его можно распечатать или воспользоваться в качестве примера и повторить его написание от руки.\n" +
      "Итак, давайте начнём!\n",
    targetDate: "12/19/2023",
    image: "Decoration-2",
  },
  {
    dayNumber: 3,
    text:
      "А вы знали, что следующий год — год Зелёного Деревянного дракона? Следующая техника связана именно с этим животным. \n" +
      "\n" +
      "Телесно-ориентированная терапия — тот подход, который помогает наладить свою связь с телом, прочувствовать зажимы и избавиться от них, чтобы свободно идти дальше.\n" +
      "Наш эксперт поможет вам испробовать этот подход на себе — благодаря предложенной технике вы сможете снизить тревожность, повысить стрессоустойчивость и наладить контакт со своей силой.\n",
    targetDate: "12/20/2023",
    image: "Decoration-3",
  },
  {
    dayNumber: 4,
    text:
      "К сожалению, в течение года происходят не только хорошие события.\n" +
      "Но именно благодаря плохим мы учимся ценить то хорошее, что есть в нашей жизни!\n" +
      "Кликните на кнопку «Открыть день».\n",
    targetDate: "12/21/2023",
    image: "Decoration-4",
  },
  {
    dayNumber: 5,
    text:
      "Когда мы были детьми, мы часто проявляли себя в творчестве. Но взрослея, многие и вовсе перестают творить: хотя очень даже зря!\n" +
      "Творчество может помочь справиться со шквалом эмоций и лучше понять себя. К счастью, существует такое направление психологии, как арт-терапия. \n" +
      "\n" +
      "Сегодня мы предлагаем вам испробовать его на себе: с помощью упражнения «Елка» вы порефлексируете насчет уходящего года и подумаете обо всем ценном, что он в себе сохранит.\n",
    targetDate: "12/22/2023",
    image: "Decoration-5",
  },
  {
    dayNumber: 6,
    text:
      "Возьмите свой шаблон, который вы заполняли и посмотрите на всё то, что вы написали про свой 2023 год в предыдущие дни.\n" +
      "Просмотрите отдельно каждый месяц, проживите их заново, вспомните все эти события — за негативные поблагодарите и отпустите, а за позитивные — просто поблагодарите. \n" +
      "Кликните на кнопку «Открыть день».\n",
    targetDate: "12/23/2023",
    image: "Decoration-6",
  },
  {
    dayNumber: 7,
    text:
      "Вот и завершается первая неделя, посвященная подведению итогов вашего 2023 года. \n" +
      "Сегодня мы предлагаем вам сделать технику из эмоционально-образной терапии — она позволит вам дать свободу бессознательным процессам и по-настоящему отпустить все то, что хочется оставить в уходящем году. Уделите время себе, подготовьте карандаши и лист бумаги, включите видео с нашим экспертом и расслабьтесь!\n" +
      "Впереди — Новый год, с новыми начинаниями и свершениями!\n",
    targetDate: "12/24/2023",
    image: "Decoration-7",
  },
  {
    dayNumber: 8,
    text:
      "Наступила вторая неделя нашего календаря — последняя неделя перед Новым 2024 годом!\n" +
      "Это значит, что теперь мы заканчиваем с подведением итогов и начинаем смотреть в будущее — ставить новые цели. \n" +
      "\n" +
      "Кликайте на кнопку «Открыть день».\n",
    targetDate: "12/25/2023",
    image: "Decoration-8",
  },
  {
    dayNumber: 9,
    text:
      "По традиции считается, что Новый год — семейный праздник. \n" +
      "Отлично, если в вашей семье — настоящая идиллия, и вы вместе готовите оливье, наряжаете ёлку и обмениваетесь подарками. Но иногда возможность провести время вместе становится поводом для конфликтов и ссор.\n" +
      "Техника, которую наш эксперт подготовил для вас сегодня, называется «Рождественские посиделки». Она подойдет для любых семей: поможет вам придумать новые традиции и научиться по-настоящему ценить время, проведенное вместе.\n",
    targetDate: "12/26/2023",
    image: "Decoration-9",
  },
  {
    dayNumber: 10,
    text:
      "Чтобы цели стали реальностью в следующем году, нужно распланировать то, как именно вы будете их достигать. \n" +
      "\n" +
      "Кликайте на кнопку «Открыть день».\n",
    targetDate: "12/27/2023",
    image: "Decoration-10",
  },
  {
    dayNumber: 11,
    text:
      "Наверняка вы слышали о том, что один из ключевых критериев успешного достижения целей — сделать их измеримыми. Ведь как можно чего-то достичь, если нет конкретики?\n" +
      "\n" +
      "Сегодня техника от нашего эксперта нацелена именно на то, чтобы сделать ваши цели и желания измеримыми и исполнимыми!\n" +
      "\n" +
      "Кликайте на кнопку «Открыть день».\n",
    targetDate: "12/28/2023",
    image: "Decoration-11",
  },
  {
    dayNumber: 12,
    text:
      "Итак, вчера вы уже составили свою систему измерений для целей — сегодня стоит это зафиксировать. \n" +
      "Перед этим предлагаем вам зажечь свечи и включить новогоднюю музыку, чтобы этот процесс стал ещё волшебнее. \n" +
      "\n" +
      "Кликайте на кнопку «Открыть день».\n",
    targetDate: "12/29/2023",
    image: "Decoration-12",
  },
  {
    dayNumber: 13,
    text:
      "Сегодня мы погрузим вас в атмосферу настоящего новогоднего чуда — наш эксперт подготовил для вас потрясающую технику из сказкотерапии, чтобы вы смогли расслабиться, отдохнуть от новогодней суеты и порадовать своего внутреннего ребёнка.\n" +
      "Включайте видео и наслаждайтесь!\n",
    targetDate: "12/30/2023",
    image: "Decoration-13",
  },
  {
    dayNumber: 14,
    text:
      "Вот и подошел к концу наш двухнедельный путь, подводящий вас к Новому 2024 году.\n" +
      "Надеемся, что техники, предложенные нашими экспертами, вам понравились и помогли немного отвлечься от новогодней суеты, вспомнив о себе и своем внутреннем состоянии.\n" +
      "\n" +
      "Команда DoTherapy очень рада поздравить вас с наступающими праздниками и пожелать всегда помнить о том, как важно заботиться и беречь себя!\n" +
      "\n" +
      "В честь этого мы дарим вам подарки: открывайте открытку и выбирайте то, что вам нравится больше всего!\n",
    targetDate: "12/31/2023",
    image: "Decoration-14",
  },
];

export default daysInfo;
