import dayjs from "dayjs";
import ICalendarDay from "../../../../../entities/calendar/interfaces/ICalendarDay";

const setActiveDayByTime = (daysInfo: ICalendarDay[], serverDateTime: string | null): number => {
  if (!serverDateTime) {
    return 0;
  }

  const dayjsDate = dayjs(serverDateTime);
  const dayIndex = daysInfo.findIndex(
    (dayInfo) => dayInfo.targetDate === dayjsDate.format("MM/DD/YYYY"),
  );
  return dayIndex === -1 ? 0 : dayIndex;
};

export default setActiveDayByTime;
