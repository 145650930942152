import styled from "@emotion/styled";
import screens from "../../../../shared/styles/screens";

export const HomePageCarouselStyled = styled.div`
  width: 100%;
  height: 480px;

  @media all and (max-width: ${screens.l}) {
    height: 800px;
  }

  @media all and (max-width: ${screens.m}) {
    height: 900px;
  }
`;
