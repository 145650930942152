const screens = {
  sm: "375px",
  m: "540px",
  md: "720px",
  l: "980px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1920px",

  // SPECIAL
  calendarImageS1: "1080px",
};

export default screens;
