import React from "react";
import { Outlet } from "react-router-dom";
import { LoggedMainContent, LoggedMainStyled } from "./loggedMain.styled";
import Header from "../../../widgets/Header/ui/Header";

const LoggedMain: React.FC = () => {
  return (
    <LoggedMainStyled>
      <Header />
      <LoggedMainContent>
        <Outlet />
      </LoggedMainContent>
    </LoggedMainStyled>
  );
};

export default LoggedMain;
