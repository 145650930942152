import styled from "@emotion/styled";
import borders from "../../../styles/borders";
import shadows from "../../../styles/shadows";
import transitions from "../../../styles/transitions";
import indents from "../../../styles/indents";
import colors from "../../../styles/colors";

export default styled.button<{
  $appearance: string;
  $hasChildren: boolean;
  $shadow: boolean;
  $hasIcon: boolean;
  disabled: boolean;
}>`
  min-width: 42px;
  padding: ${indents.i12} ${indents.i8};
  background: none;
  border: none;
  text-decoration: none;
  border-radius: ${borders.full};
  box-shadow: ${(props): string => (props.$shadow ? shadows.sh1 : "none")};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "CoFo", serif;
  display: flex;
  align-items: center;
  justify-content: ${(props): string => (props.$hasIcon ? "left" : "center")};
  cursor: ${(props): string => (props.disabled ? "not-allowed" : "pointer")};
  transition: ease-in-out ${transitions.t3};

  svg {
    padding: ${(props): string =>
      props.$hasChildren
        ? `${indents.i0} ${indents.i0} ${indents.i0} ${indents.i12}`
        : `${indents.i0} ${indents.i8}`};
  }

  ${(props): string => props.$appearance ?? ""}
  ${(props): string => {
    return props.disabled
      ? `
      background: ${colors.white.d7} !important;
      border-color: ${colors.white.d7} !important;
       `
      : "";
  }}
`;
