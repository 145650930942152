import React from "react";
import { FaCaretDown } from "react-icons/fa";
import {
  SubMenuLabel,
  SubMenuLink,
  SubMenuLinks,
  SubMenuLinksContainer,
  SubMenuStyled,
} from "./subMenu.styled";
import IHeaderLink from "../../../model/interfaces/IHeaderLink";

const SubMenu: React.FC<{ headerLink: IHeaderLink }> = ({ headerLink }) => {
  return (
    <SubMenuStyled>
      <SubMenuLabel>
        {headerLink.label}
        <FaCaretDown />
      </SubMenuLabel>
      <SubMenuLinksContainer className="subMenuLinksContainer">
        <SubMenuLinks>
          {headerLink.sublinks &&
            headerLink.sublinks.map((sublink) => (
              <SubMenuLink key={sublink.label} href={sublink.link}>
                {sublink.label}
              </SubMenuLink>
            ))}
        </SubMenuLinks>
      </SubMenuLinksContainer>
    </SubMenuStyled>
  );
};

export default SubMenu;
